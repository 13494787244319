import {
  memo,
} from 'react';
import {
  defs as p,
} from './_params';





export default memo(function ReelsSvgDefs() {
  return (
    <defs>
{/* Filters */}
      <filter id="filter__reel-shadow">
        <feGaussianBlur stdDeviation={10} />
      </filter>
      <filter
        id="filter__slot-shadow"
        x="-2.5%"
        y="-2.5%"
        width="105%"
        height="105%"
        filterUnits="objectBoundingBox"
      >
        <feMorphology radius={5} operator="erode" in="SourceAlpha" result="shadowSpreadInner1" />
        <feGaussianBlur stdDeviation={5} in="shadowSpreadInner1" result="shadowBlurInner1" />
        <feComposite in="shadowBlurInner1" in2="SourceAlpha" operator="arithmetic" k2={-1} k3={1} result="shadowInnerInner1" />
        <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowInnerInner1" />
      </filter>
{/* Linear gradients */}
      <linearGradient
        id="grad__sprocket-spoke-contour"
        x1="0%"
        y1="100%"
        x2="0%"
        y2="0%"
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} />
        <stop offset={.5} stopOpacity={0} />
        <stop offset={1} />
      </linearGradient>
{/* Radial gradients */}
      <radialGradient
        id="grad__rivet-shadow"
        cx={.5}
        cy={.5}
        r={.5}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} />
        <stop offset={1} stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="grad__rivet-contour"
        cx={.5}
        cy={.5}
        r={.5}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopOpacity={.7} />
        <stop offset={.31} stopOpacity={.5} />
        <stop offset={.6} stopOpacity={0} />
        <stop offset={1} />
      </radialGradient>
      <radialGradient
        id="grad__sprocket-nub-contour"
        cx={.5}
        cy={.5}
        r={.5}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopOpacity={0} />
        <stop offset={1} stopOpacity={.8} />
      </radialGradient>
      <radialGradient
        id="grad__sprocket-base-inner-contour"
        cx={.5}
        cy={.5}
        r={.5}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopOpacity={0} />
        <stop offset={.95} stopOpacity={.25} />
        <stop offset={1} stopOpacity={.8} />
      </radialGradient>
      <radialGradient
        id="grad__sprocket-rim-shadow"
        cx={.5}
        cy={.5}
        r={.5}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} />
        <stop offset={.95} stopOpacity={.8} />
        <stop offset={1} stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="grad__sprocket-rim-outer-contour"
        cx={.5}
        cy={.5}
        r={.5}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopOpacity={0} />
        <stop offset={.96} stopOpacity={0} />
        <stop offset={1} stopOpacity={.8} />
      </radialGradient>
      <radialGradient
        id="grad__sprocket-rim-inner-contour"
        cx={.5}
        cy={.5}
        r={.5}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopOpacity={.2} />
        <stop offset={.85} stopOpacity={.2} />
        <stop offset={1} stopOpacity={.8} />
      </radialGradient>
      <radialGradient
        id="grad__spindle-contour"
        cx={.5}
        cy={.5}
        r={.5}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopOpacity={0} />
        <stop offset={.42} stopOpacity={.1} />
        <stop offset={.71} stopOpacity={.3} />
        <stop offset={1} stopOpacity={.8} />
      </radialGradient>
{/* Shapes */}
      <circle
        id="circle__base"
        cx={p.cx}
        cy={p.cy}
        r={p.r}
      />
      <circle
        id="circle__rivet--shadow"
        cx={647}
        cy={377}
        r={25}
      />
      <circle
        id="circle__rivet"
        cx={647}
        cy={377}
        r={18.75}
      />
      <circle
        id="circle__sprocket-nub"
        cx={375}
        cy={p.cy}
        r={15}
      />
      <circle
        id="circle__spindle"
        cx={p.cx}
        cy={p.cy}
        r={22}
      />
      <path
        id="path__slot"
        d="M227.76,514.17,87.13,686.58a419.48,419.48,0,0,0,77.69,135.31l1.31,1.53,219.63-35.59,23.8-58.77q-58.32-35-88.08-86.56-29.44-51-30.89-118.2l0-1.36Z"
      />
      <path
        id="path__sprocket-spoke"
        d="M508.91,510a22,22,0,0,0,0,30h-103a121.57,121.57,0,0,1-.16-28.66l.16-1.34Zm135.16,0a121.57,121.57,0,0,1,.16,28.66l-.16,1.34h-103a22,22,0,0,0,0-30Z"
      />
{/* Images */}
      <image
        id="image__reel-bg"
        href={p.imgReelBg}
        x={0}
        y={0}
        width={p.widthReel}
        height={p.heightReel}
        preserveAspectRatio="none"
      />
{/* Groups_00 */}
      <g id="g0__slot">
        <use
          xlinkHref="#path__slot"
          filter="url(#filter__slot-shadow)"
        />
        <use
          xlinkHref="#path__slot"
          fill="none"
          stroke={p.colorBlack}
        />
      </g>
      <g id="g0__rivet">
        <use
          xlinkHref="#circle__rivet--shadow"
          fill="url(#grad__rivet-shadow)"
        />
        <use
          xlinkHref="#circle__rivet"
          fill={p.colorWhite}
        />
        <use
          xlinkHref="#circle__rivet"
          fill="url(#grad__rivet-contour)"
        />
      </g>
      <g id="g0__sprocket-nub">
        <use
          xlinkHref="#circle__sprocket-nub"
          fill="url(#grad__sprocket-nub-contour)"
        />
      </g>
      <g id="g0__sprocket-spoke">
        <use
          xlinkHref="#path__sprocket-spoke"
          fill="url(#grad__sprocket-spoke-contour)"
        />
      </g>
{/* Groups_01 */}
      <g id="g1__slots" >
        <use xlinkHref="#g0__slot" />
        <use xlinkHref="#g0__slot" transform={`rotate(120, ${p.cx}, ${p.cy})`} />
        <use xlinkHref="#g0__slot" transform={`rotate(240, ${p.cx}, ${p.cy})`} />
      </g>
      <g id="g1__rivets" >
        <use xlinkHref="#g0__rivet" />
        <use xlinkHref="#g0__rivet" transform={`rotate(120, ${p.cx}, ${p.cy})`} />
        <use xlinkHref="#g0__rivet" transform={`rotate(240, ${p.cx}, ${p.cy})`} />
      </g>
      <g id="g1__sprocket-nubs" >
        <use xlinkHref="#g0__sprocket-nub" />
        <use xlinkHref="#g0__sprocket-nub" transform={`rotate(60, ${p.cx}, ${p.cy})`} />
        <use xlinkHref="#g0__sprocket-nub" transform={`rotate(120, ${p.cx}, ${p.cy})`} />
        <use xlinkHref="#g0__sprocket-nub" transform={`rotate(180, ${p.cx}, ${p.cy})`} />
        <use xlinkHref="#g0__sprocket-nub" transform={`rotate(240, ${p.cx}, ${p.cy})`} />
        <use xlinkHref="#g0__sprocket-nub" transform={`rotate(300, ${p.cx}, ${p.cy})`} />
      </g>
      <g id="g1__sprocket-spokes" >
        <use xlinkHref="#g0__sprocket-spoke" />
        <use xlinkHref="#g0__sprocket-spoke" transform={`rotate(120, ${p.cx}, ${p.cy})`} />
        <use xlinkHref="#g0__sprocket-spoke" transform={`rotate(240, ${p.cx}, ${p.cy})`} />
      </g>
{/* Groups */}
  {/* OVERLAY */}
      <g id="g__reel-ovarlay">
    {/* outline */}
        <use
          xlinkHref="#circle__base"
          fill="none"
          stroke={p.colorBlack}
        />
    {/* slots */}
        <use xlinkHref="#g1__slots" />
    {/* rivets */}
        <use xlinkHref="#g1__rivets" />
    {/* sprocket */}
        <path
          d="M525,375a149.43,149.43,0,0,1,61.65,13.22l.36-.62a15,15,0,0,1,26,15l-.37.64A149.88,149.88,0,0,1,674.26,510H675a15,15,0,0,1,0,30h-.74a149.85,149.85,0,0,1-61.64,106.76l.37.64a15,15,0,1,1-26,15h0l-.36-.62a150.33,150.33,0,0,1-123.3,0l-.36.62a15,15,0,1,1-26-15h0l.37-.64A149.82,149.82,0,0,1,375.74,540H375a15,15,0,0,1,0-30h.74a149.87,149.87,0,0,1,61.64-106.76l-.37-.64a15,15,0,0,1,26-15l.36.62A149.49,149.49,0,0,1,525,375Z"
          fill={p.colorDarkGray}
        />
        <use xlinkHref="#g1__sprocket-nubs" />
        <path
          d="M525,375a149.52,149.52,0,0,1,61.67,13.22,15,15,0,0,0,25.93,15A149.88,149.88,0,0,1,674.26,510a15,15,0,0,0,0,30A149.86,149.86,0,0,1,612.6,646.77a15,15,0,0,0-25.93,15,150.43,150.43,0,0,1-123.34,0,15,15,0,0,0-25.93-15A149.86,149.86,0,0,1,375.74,540a15,15,0,0,0,0-30,149.89,149.89,0,0,1,61.65-106.79,15,15,0,0,0,25.94-15A149.35,149.35,0,0,1,525,375Z"
          fill="url(#grad__sprocket-base-inner-contour)"
        />
        <path
          d="M525,375.5a149,149,0,0,1,61.86,13.36h0l.58-1a14.5,14.5,0,1,1,25.12,14.5h0l-.6,1A149.41,149.41,0,0,1,673.81,510.5H675a14.5,14.5,0,0,1,0,29h-1.19A149.38,149.38,0,0,1,612,646.62h0l.6,1a14.5,14.5,0,1,1-25.12,14.5h0l-.58-1a149.92,149.92,0,0,1-123.72,0h0l-.58,1a14.5,14.5,0,0,1-25.12-14.5h0l.6-1A149.4,149.4,0,0,1,376.19,539.5H375a14.5,14.5,0,0,1,0-29h1.19A149.38,149.38,0,0,1,438,403.38h0l-.6-1a14.5,14.5,0,0,1,25.12-14.5h0l.58,1A149,149,0,0,1,525,375.5Z"
          fill="none"
          stroke={p.colorBlack}
        />
        <circle
          cx={p.cx}
          cy={p.cy}
          r={150}
          fill="url(#grad__sprocket-rim-shadow)"
        />
        <circle
          cx={p.cx}
          cy={p.cy}
          r={143}
          fill={p.colorDarkGray}
        />
        <circle
          cx={p.cx}
          cy={p.cy}
          r={143}
          fill="url(#grad__sprocket-rim-outer-contour)"
        />
        <use xlinkHref="#g1__sprocket-spokes" />
        <circle
          cx={p.cx}
          cy={p.cy}
          r={120}
          fill="url(#grad__sprocket-rim-inner-contour)"
        />
    {/* spindle */}
        <use
          xlinkHref="#circle__spindle"
          fill={p.colorDarkWhite}
        />
        <use
          xlinkHref="#circle__spindle"
          fill="url(#grad__spindle-contour)"
        />
        <use
          xlinkHref="#circle__spindle"
          fill="none"
          stroke={p.colorBlack}
          strokeWidth={2}
        />
      </g>
  {/* SHADOW */}
      <g id="g__reel-shadow">
        <use
          xlinkHref="#circle__base"
          filter="url(#filter__reel-shadow)"
          transform={`translate(${p.offsetReelShadow}, ${p.offsetReelShadow})`}
          fill="rgba(0, 0, 0, .3)"
        />
      </g>
  {/* MASK */}
      <g id="g__reel-bg--mask">
        <use
          xlinkHref="#circle__base"
          fill="#000000"
          stroke="none"
        />
        <path
          d="M525,.5C669.84.5,801,59.21,895.88,154.12S1049.5,380.16,1049.5,525,990.79,801,895.88,895.88,669.84,1049.5,525,1049.5,249,990.79,154.12,895.88.5,669.84.5,525,59.21,249,154.12,154.12,380.16.5,525,.5ZM227.55,513.63l-141,172.85.67,1.91a420.31,420.31,0,0,0,77.21,133.83h0l1.49,1.73,220.19-35.67,24.07-59.43-1.17-.7q-57.61-34.83-87.1-85.9-29.4-50.91-30.83-118h0l0-1.78Zm594.9,0-63.51,8.88,0,1.36q-1.35,67.3-30.84,118.38-29.39,50.91-86.74,85.68h0l-1.53.92,24.07,59.43L884.07,824l1.31-1.53a420.16,420.16,0,0,0,77.3-133.77h0l.76-2.16ZM525,57a422.74,422.74,0,0,0-77.12,7.14h0l-2.25.42-79.2,208.53,39.44,50.55,1.19-.65Q466,290.5,525,290.5q58.79,0,117.57,32.28h0l1.56.86,39.44-50.55L604.37,64.56l-2-.37A423,423,0,0,0,525,57Z"
          fill="#ffffff"
          stroke="none"
        />
      </g>
    </defs>
  );
});
