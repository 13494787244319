import {
  Analyser,
  Machine,
} from './modules';





export default class AudioTapeUI {

/* ------------------------------------------------------------------ */
/* Private fields */

  // AudioTape instance
  #Tape;

  // Child class instances
  #Analyser;
  #Machine;

  // Bound event listeners
  #handleMediaReady;
  #handleTick;

  // State management
  #tickRaf;
  #tick;
  #updateState;



/* ------------------------------------------------------------------ */
/* Static Properties */

  static DEFAULT_SCALAR = 100;
  static DEFAULT_IPS = 7.5;
  static DEFAULT_ANALYSER_PARAMS = {
    ids: ['MeterLeft', 'MeterRight'],
    fftSize: 2 ** 10,   // 1024
    minDecibels: -100,
    maxDecibels: -30,
    smoothingTimeConstant: 0,
    ballistics: (1 / 16),
    peakLevel: 15,
    peakHold: 1e3,
  };
  static DEFAULT_MACHINE_PARAMS = {
    ids: {
      counter: 'Counter',
      reels: ['ReelLeft', 'ReelRight'],
    },
    uiScalar: this.DEFAULT_SCALAR,
    ips: this.DEFAULT_IPS,
    reelDiameter: 10.5,         // inches
    tapeDiameter: 9.5,          // inches
    hubDiameter: 3,             // inches
    durationSeconds: 48 * 60,   // seconds
    counterDigits: 5,
  };



/* ------------------------------------------------------------------ */
/* Static Methods */

  static VALID_params(params = {}, defaultParams = {}) {
    const validParams = {};
    for (const p in defaultParams) {
      validParams[p] = params[p] ?? defaultParams[p];
    };
    return validParams;
  };



/* ------------------------------------------------------------------ */
/* Getters */

  get sampleRate() {
    return this.#Tape.sampleRate;
  };



/* ------------------------------------------------------------------ */
/* Setters */

  set analyserCb(fn) {
    if (typeof fn !== 'function') {
      throw new TypeError('analyserCb must be a function');
    };
    this.#Analyser.updateCb = fn;
  };

  set machineCb(fn) {
    if (typeof fn !== 'function') {
      throw new TypeError('machineCb must be a function');
    };
    this.#Machine.updateCb = fn;
  };



/* ------------------------------------------------------------------ */
/* Constructor */

  constructor(
    AudioTape,
    {
      analyser,
      machine,
    } = {},
  ) {
    /* AudioTape instance */
    this.#Tape = AudioTape;
    /* Child class instances */
    this.#Analyser = new Analyser(
      this.#Tape._gainNode,
      this.constructor.VALID_params(analyser, this.constructor.DEFAULT_ANALYSER_PARAMS),
    );
    this.#Machine = new Machine(
      this.constructor.VALID_params(machine, this.constructor.DEFAULT_MACHINE_PARAMS),
    );
    /* Private methods -- bound event listeners */
    this.#handleMediaReady = this._handleMediaReady.bind(this);
    // this.#handleTick = this._handleTick.bind(this);
    /* Private event listener registration */
    this.#Tape.on('mediaready', this.#handleMediaReady);
    // this.#Tape.on('tick', this.#handleTick);
    /* State management */
    this.#tick = this._tick.bind(this);
    this.#tickRaf = null;
    this.#updateState = this._updateState.bind(this);
  };



/* ------------------------------------------------------------------ */
/* Event listeners -- private bound */

/* */
  _handleMediaReady(e) {
    this.#Machine.duration = this.#Tape.totalLengthSeconds;
    this.#tick();
  };


/* */
  // async _handleTick(e) {
  //   console.log(e.playhead)
  //   this.#updateState(0, e.playhead);
  //   const a = await this.#Analyser.getState();
  //   const b = await this.#Machine.getState(e.playhead);
  //   console.log(a)
  //   console.log(b)

  //   const state = {};
  //   this.#Analyser.getState()
  //     .then(aState => Object.assign(state, aState));
  //   this.#Machine.getState(e.playhead)
  //     .then(mState => Object.assign(state, mState));

  //   const state = {};
  //   await Promise.all([
  //     this.#Analyser.getState().then(aState => Object.assign(state, aState)),
  //     this.#Machine.getState(e.playhead).then(mState => Object.assign(state, mState)),
  //   ]);
  //   console.log(state)
  // };


/* ------------------------------------------------------------------ */
/* State Management */


// TO DO: read 'currentSpeed' from #Tape and use that to update rotation.

  _tick(ts) {
    this.#tickRaf = requestAnimationFrame(this.#tick);
    const playhead = this.#Tape.playhead;
    this.#updateState(ts, playhead);
  };

  _updateState(ts, playhead) {
    this.#Analyser.update(ts, playhead);
    this.#Machine.update(ts, playhead);
  };



};
