import {
  memo,
} from 'react';
import './Transport.css';
import {
  params,
  TransportButton,
} from './';





export default memo(function Transport({
  active = '',
  toggle = null,
}) {

  return (
    <svg
      className="Transport"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${params.width} ${params.height}`}
      width={params.width}
      height={params.height}
    >
      <defs>
        <filter id="filter__transport-shadow--md">
          <feGaussianBlur stdDeviation={5} />
        </filter>
        <filter id="filter__transport-shadow--lg">
          <feGaussianBlur stdDeviation={7} />
        </filter>
        <radialGradient
          id="grad__transport-button--contour"
          cx={.5}
          cy={.5}
          r={.5}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopOpacity={0} />
          <stop offset={.95} stopOpacity={.25} />
        </radialGradient>
      </defs>
      {Object.entries(params.buttons).map(([key, b]) => (
        <TransportButton
          key={key}
          {...b}
          action={key}
          active={active === key}
          click={() => toggle(key)}
        />
      ))}
    </svg>
  );
});
