import {
  useEffect,
  useState,
} from 'react';
import {
  Tape,
} from '../';





////////////////////////////////////////////////////////////////////////////////
/* isPlaying hook export */

export default function usePlaying() {
  const [playing, setPlaying] = useState(false);

/* register event listener to engine + toggle stop */
  useEffect(() => {
    const isPlaying = () => setPlaying(true);
    const notPlaying = () => setPlaying(false);
    Tape.addEventListener('enginestop', notPlaying);
    Tape.addEventListener('enginestart', isPlaying);
    return () => {
      Tape.removeEventListener('enginestop', notPlaying);
      Tape.removeEventListener('enginestart', isPlaying);
    }
  }, []);

  return playing;
};
