import { AnalyserChannel } from './';





export default class Analyser {

/* ------------------------------------------------------------------ */
/* Private fields */

  // State
  #lastPlayhead;
  #freeze;

  // Fixed constants
  #analyserParams;

  // Properties exposed via getters/setters
  #ballistics;
  #peakLevel;
  #peakHold;
  #updateCb;

  // Instantiation constants
  #splitter;
  #channels;
  #updateFn;



/* ------------------------------------------------------------------ */
/* Static Properties */

  // default values -- AnalyserNode parameters
  static DEFAULT_FFT_SIZE = 2 ** 10;
  static DEFAULT_MIN_DECIBELS = -100;
  static DEFAULT_MAX_DECIBELS = -30;
  static DEFAULT_SMOOTHING_TIME_CONSTANT = 0;

  // default values -- ChannelAnalyser parameters
  static DEFAULT_BALLISTICS = (1 / 18);
  static DEFAULT_PEAK_LEVEL = 15;
  static DEFAULT_PEAK_HOLD = 1e3;



/* ------------------------------------------------------------------ */
/* Getters */

  get ready() {
    if (!this.#channels.every(c => c.ready)) {
      return false;
    };
    return true;
  };

  get ballistics() {
    return this.#ballistics;
  };

  get peakLevel() {
    return this.#peakLevel;
  };

  get peakHold() {
    return this.#peakHold;
  };



/* ------------------------------------------------------------------ */
/* Setters */

  set ballistics(v) {
    this.#channels.every(c => c.ballistics = v);
    this.#ballistics = v;
  };

  set peakLevel(v) {
    this.#channels.every(c => c.peakLevel = v);
    this.#peakLevel = v;
  };

  set peakHold(v) {
    this.#channels.every(c => c.peakHold = v);
    this.#peakHold = v;
  };

  set updateCb(fn) {
    if (typeof fn !== 'function') {
      throw new TypeError('updateCb must be a function');
    };
    this.#updateCb = fn;
  };



/* ------------------------------------------------------------------ */
/* Constructor */

  constructor(
    gainNode,
    {
      /* Fixed at instantiation */
      ids = [],
      fftSize = this.constructor.DEFAULT_FFT_SIZE,
      minDecibels = this.constructor.DEFAULT_MIN_DECIBELS,
      maxDecibels = this.constructor.DEFAULT_MAX_DECIBELS,
      smoothingTimeConstant = this.constructor.DEFAULT_SMOOTHING_TIME_CONSTANT,
      /* Exposed via getters/setters */
      ballistics = this.constructor.DEFAULT_BALLISTICS,
      peakLevel = this.constructor.DEFAULT_PEAK_LEVEL,
      peakHold = this.constructor.DEFAULT_PEAK_HOLD,
    } = {},
  ) {
    /* State */
    this.#lastPlayhead = 0;
    this.#freeze = true;
    /* Fixed constants */
    this.#analyserParams = {
      fftSize,
      minDecibels,
      maxDecibels,
      smoothingTimeConstant,
    };
    /* Properties exposed via getters/setters */
    this.#ballistics = this.constructor.DEFAULT_BALLISTICS;
    this.#peakLevel = this.constructor.DEFAULT_PEAK_LEVEL;
    this.#peakHold = this.constructor.DEFAULT_PEAK_HOLD;
    this.#updateCb = () => {};
    /* Instantiation constants */
    this.#splitter = this._createChannelSplitter(gainNode);
    this.#channels = this._createAnalyserChannels(ids, this.#splitter);
    this.#updateFn = this._createUpdateFn(this.#channels).bind(this);
    /* Initialization */
    this.ballistics = ballistics;
    this.peakLevel = peakLevel;
    this.peakHold = peakHold;
    /* Public methods */
    this.getState = this.#updateFn.bind(this);
    this.update = this._update.bind(this);
  };



/* ------------------------------------------------------------------ */
/* Setup */

  _createChannelSplitter(gainNode) {
    const { channelCount, context } = gainNode;
    const splitter = context.createChannelSplitter(channelCount);
    gainNode.connect(splitter);
    return splitter;
  };

  _createAnalyserChannels(ids = [], splitter) {
    const { channelCount } = splitter;
    const channels = [];
    for (let i = 0; i < channelCount; i++) {
      const id = ids[i] || i;
      const channel = this._createAnalyserChannel(id, splitter, i);
      channels.push(channel);
    };
    return channels;
  };

  _createAnalyserChannel(id, splitter, channel = 0) {
    const { context } = splitter;
    const analyser = context.createAnalyser();
    analyser.fftSize = this.#analyserParams.fftSize;
    analyser.minDecibels = this.#analyserParams.minDecibels;
    analyser.maxDecibels = this.#analyserParams.maxDecibels;
    analyser.smoothingTimeConstant = this.#analyserParams.smoothingTimeConstant;
    splitter.connect(analyser, channel);
    return new AnalyserChannel({
      id: id,
      analyser: analyser,
      ballistics: this.#ballistics,
      peakLevel: this.#peakLevel,
      peakHold: this.#peakHold,
    });
  };

  _createUpdateFn(channels) {
    const fns = channels.map(c => c.analyse);
    return function() {
      const state = {};
      return Promise.all(
        fns.map(fn => fn().then(r => Object.assign(state, r)))
      ).then(() => state);
    };
  };



/* ------------------------------------------------------------------ */
/* Update */

  async _update(ts, playhead) {
    if (playhead !== this.#lastPlayhead && this.#freeze) {
      this.#freeze = false;
    };
    if (this.#freeze) {
      return null;
    };
    this.#lastPlayhead = playhead;
    const nextState = await this.getState();
    if (!Object.keys(nextState).length) {
      this.#freeze = true;
      return null;
    };
    this.#updateCb(nextState);
  };



};
