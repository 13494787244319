import {
  memo,
} from 'react';
import p from '../_params';
import {
  TapeReel,
} from './';





export default memo(function Tape({
  rLeft = 0,
  rRight = 0,
} = {}) {
  return (
    <svg
      className="Tape Machine-layer"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${p.width} ${p.height}`}
      width={p.width}
      height={p.height}
    >
{/* Tape Reels */}
      <TapeReel
        r={rLeft}
        cx={p.cxReelLeft}
      />
      <TapeReel
        r={rRight}
        cx={p.cxReelRight}
      />
    </svg>
  );
});
