import {
  memo,
} from 'react';
import {
  classname as c,
} from '../../libs';





export default memo(function IconMenu({
  cl = '',
  ...attr
} = {}) {
  return (
    <svg
      className={c.concat('Icon Icon-menu', cl)}
      xmlns="http://www.w3.org/2000/svg"
      width={10}
      height={10}
      viewBox="0 0 10 10"
      {...attr}
    >
      <path
        d="M 2 3 H 8 M 2 5 H 8 M 2 7 H 8"
        stroke="#C1C5C7"
        strokeWidth={.5}
      />
    </svg>
  );
});
