import {
  Suspense,
  lazy,
} from 'react';
import {
  useLocation,
} from 'react-router-dom';
import './AudioTape.css';
import {
  classname as c,
} from '../../libs';

const Player = lazy(() => import('../../Player'));





export default function AudioTape() {
  const { pathname } = useLocation();
  return (
    <div className={c.toggle('Audio-tape', { hidden: (pathname !== '/') })}>
      <Suspense fallback={null}>
        <Player />
      </Suspense>
    </div>
  );
};
