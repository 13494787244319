import {
  Fragment as F,
  memo,
  useMemo,
} from 'react';
import p from '../_params';





export default memo(function MechArm({
/* side-specific */
  cx = 0,
  cy = 0,
/* state- and side-specific */
  cxRoller = 0,
  cyRoller = 0,
  angle = 0,
} = {}) {

  const armPath = useMemo(() => {
    return `
      M ${cx + p.rArmAnchor},${cy}
      L ${cx + p.rArmRoller},${cy + p.lengthArm}
      Q ${cx + p.rArmRoller},${cy + p.lengthArm + p.rArmRoller} ${cx},${cy + p.lengthArm + p.rArmRoller}
      Q ${cx - p.rArmRoller},${cy + p.lengthArm + p.rArmRoller} ${cx - p.rArmRoller},${cy + p.lengthArm}
      L ${cx - p.rArmAnchor},${cy}
      Q ${cx - p.rArmAnchor},${cy - p.rArmAnchor} ${cx},${cy - p.rArmAnchor}
      Q ${cx + p.rArmAnchor},${cy - p.rArmAnchor} ${cx + p.rArmAnchor},${cy}
      Z
    `.replace(/\s+/g, ' ');
  }, [cx, cy]);

  return (
    <F>
      <path /* Arm shadow */
        d={armPath}
        transform={`translate(${6}, ${4})rotate(${angle - 180} ${cx} ${cy})`}
        filter="url(#filter__mech-shadow--md)"
        stroke="none"
        fill={p.colorBlack}
        opacity={.8}
      />
      <g transform={`rotate(${angle - 180} ${cx} ${cy})`}>
        <path /* Arm fill + outline */
          d={armPath}
          stroke={p.colorOutline}
          strokeWidth={2}
          fill={p.colorMetal}
        />
        <path /* Arm gradient -- horiz */
          d={armPath}
          stroke="none"
          fill="url(#grad__mech-arm-contour--h)"
          opacity={.5}
        />
        <path /* Arm gradient -- vert */
          d={armPath}
          stroke="none"
          fill="url(#grad__mech-arm-contour--v)"
          opacity={.7}
        />
      </g>

      <circle /* Roller shadow */
        cx={cxRoller}
        cy={cyRoller}
        r={p.rArmRoller}
        filter="url(#filter__mech-shadow--sm)"
        transform={`translate(${3}, ${3})`}
        fill={p.colorBlack}
        opacity={.8}
      />
      <circle /* Roller fill + border */
        cx={cxRoller}
        cy={cyRoller}
        r={p.rArmRoller}
        stroke={p.colorTape}
        strokeWidth={p.widthTape * 2}
        fill={p.colorMetalLight}
      />
      <circle /* Roller gradient */
        cx={cxRoller}
        cy={cyRoller}
        r={p.rArmRoller}
        stroke="none"
        fill="url(#grad__mech-arm-roller)"
      />
      <circle /* Roller glare */
        cx={cxRoller}
        cy={cyRoller}
        r={p.rArmRoller}
        stroke="none"
        fill="url(#grad__mech-glare)"
      />

      <circle /* Anchor shadow */
        cx={cx}
        cy={cy}
        r={p.rArmAnchor * .65}
        stroke="none"
        filter="url(#filter__mech-shadow--sm)"
        transform={`translate(${3}, ${2})`}
        fill={p.colorBlack}
        opacity={.7}
      />
      <circle /* Anchor fill + border */
        cx={cx}
        cy={cy}
        r={p.rArmAnchor * .65}
        stroke={p.colorOutline}
        strokeWidth={3}
        fill={p.colorMetal}
      />
      <circle /* Anchor glare */
        cx={cx}
        cy={cy}
        r={p.rArmAnchor * .65}
        stroke="none"
        fill="url(#grad__mech-glare)"
        opacity={.5}
      />
      <circle /* Anchor gradient */
        cx={cx}
        cy={cy}
        r={p.rArmAnchor * .65}
        stroke="none"
        fill="url(#grad__mech-arm-anchor)"
      />
    </F>
  );
});
