import {
  memo,
} from 'react';
import {
  transformDigitNumbers,
} from './_params';





export default memo(function CounterDigit({
  x = 0,
  value = 0,
} = {}) {
  return (
    <g>
      <use /* background */
        xlinkHref="#g__counter-digit-bg"
        x={x}
      />
      <use /* number */
        xlinkHref="#g__counter-digit-numbers"
        x={x}
        transform={transformDigitNumbers(value)}
      />
      <use /* contour */
        xlinkHref="#g__counter-digit-contour"
        x={x}
      />
    </g>
  );
});
