import {
  useEffect,
  useReducer,
} from 'react';
import {
  TapeUI,
} from '../';





////////////////////////////////////////////////////////////////////////////////
/* Analyser hook export */

function analyserReducer(state, dispatch) {
  return {
    ...state,
    ...dispatch,
  };
};

const analyserState = {
  rotationMeterLeft: -48,
  peakMeterLeft: false,
  rotationMeterRight: -48,
  peakMeterRight: false,
};



export default function useAnalyser() {
  const [state, dispatch] = useReducer(analyserReducer, analyserState);

  useEffect(() => {
    TapeUI.analyserCb = dispatch;
    return () => {
      TapeUI.analyserCb = () => null;
    };
  }, []);

  return state;
};
