import {
  memo,
} from 'react';
import {
  defs as p,
  yDigitNumbersIndex,
} from './_params';





export default memo(function CounterSvgDefs() {
  return (
    <defs>
{/* Linear gradients */}
      <linearGradient
        id="grad__counter-frame-contour-x"
        x1="0%"
        y1="0%"
        x2="50%"
        y2="0%"
        spreadMethod="reflect"
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} />
        <stop offset={.05} stopOpacity={.6} />
        <stop offset={.2} stopOpacity={0} />
        <stop offset={1} stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="grad__counter-digit-contour-y"
        x1="0%"
        y1="0%"
        x2="0%"
        y2="50%"
        spreadMethod="reflect"
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} />
        <stop offset={.15} stopOpacity={.8} />
        <stop offset={.5} stopOpacity={.4} />
        <stop offset={.7} stopOpacity={.1} />
        <stop offset={.9} stopOpacity={.05} stopColor="#ffffff" />
        <stop offset={1} stopOpacity={.1} stopColor="#ffffff" />
      </linearGradient>
      <linearGradient
        id="grad__counter-digit-contour-x"
        x1="0%"
        y1="0%"
        x2="50%"
        y2="0%"
        spreadMethod="reflect"
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} />
        <stop offset={.05} />
        <stop offset={.1} stopOpacity={0} />
        <stop offset={1} stopOpacity={0} />
      </linearGradient>
{/* Shapes */}
      <rect
        id="rect__counter-frame"
        x={p.xFrame}
        y={p.yFrame}
        width={p.widthFrame}
        height={p.heightFrame}
        rx={p.rxFrame}
      />
      <rect
        id="rect__counter-digit"
        x={p.xDigit}
        y={p.yDigit}
        width={p.widthDigit}
        height={p.heightDigit}
      />
{/* Groups */}
  {/* FRAME */}
      <g id="g__counter-frame">
        <use
          xlinkHref="#rect__counter-frame"
          fill="url(#grad__counter-frame-contour-x)"
        />
        <use
          xlinkHref="#rect__counter-frame"
          fill="none"
          stroke={p.colorFrame}
          strokeWidth={p.strokeWidthFrame}
        />
      </g>
  {/* DIGIT_BG */}
      <g id="g__counter-digit-bg">
        <use
          xlinkHref="#rect__counter-digit"
          fill={p.colorDigitBgFill}
          stroke={p.colorDigitBgStroke}
          strokeWidth={p.strokeWidthDigitBg}
        />
      </g>
  {/* DIGIT_NUMBERS */}
      <g id="g__counter-digit-numbers">
        <g
          style={p.styleDigitNumbers}
          fill={p.colorDigitNumbersFill}
        >
          {p.numbers.map((d, i) => (
            <text
              key={`counter-digit-numbers-${i}`}
              x={p.xDigitNumbers}
              y={yDigitNumbersIndex(i)}
              textAnchor="middle"
              alignmentBaseline="middle"
              lengthAdjust="spacingAndGlyphs"
            >{d}</text>
          ))}
        </g>
      </g>
  {/* DIGIT_CONTOUR */}
      <g id="g__counter-digit-contour">
        <use
          xlinkHref="#rect__counter-digit"
          fill="url(#grad__counter-digit-contour-y)"
        />
        <use
          xlinkHref="#rect__counter-digit"
          fill="url(#grad__counter-digit-contour-x)"
        />
      </g>
    </defs>
  );
});
