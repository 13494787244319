import {
  useState,
  useEffect,
  useCallback,
} from 'react';
import {
  NavLink,
  useHistory,
  useLocation,
} from 'react-router-dom';
import './Nav.css';
import {
  classname as c,
} from '../../libs';
import {
  usePlaying,
} from '../../Player/hooks';
import {
  IconHome,
  IconMenu,
} from './';





export default function Nav({ routes = {} } = {}) {
  const location = useLocation();
  const history = useHistory();
  const isPlaying = usePlaying();

  useEffect(() => {
    if (history?.action === 'PUSH') {
      window.scrollTo(0, 0)
    };
  }, [location, history]);

  const [showMenu, setShowMenu] = useState(false);

  const handleClick = useCallback(e => {
    e.preventDefault();
    setShowMenu(!showMenu);
  }, [showMenu, setShowMenu]);

  useEffect(() => {
    const reset = e => showMenu && setShowMenu(false);
    window.addEventListener('click', reset);
    return () => {
      window.removeEventListener('click', reset);
    };
  }, [showMenu, setShowMenu]);


  return (
    <nav className="Nav">
      <div className="Nav__content">
        <div className="Nav__left">
          <NavLink
            className="Nav__link"
            activeClassName="Nav__link--active"
            exact
            to="/"
          >
            <div className="Nav__home">
              <IconHome
                cl={c.toggle('Nav__home-icon', ['spin', isPlaying])}
                aria-label="home"
              />
            </div>
          </NavLink>
        </div>
        <div className="Nav__right">
          <div
            className={c.toggle('Nav__menu', ['active', showMenu])}
            onClick={handleClick}
          >
            <IconMenu
              cl="Nav__menu-icon"
              aria-label="menu"
            />
          </div>
          <div className={c.toggle('Nav__links', ['active', showMenu])}>
            {Object.entries(routes).map(([pageId, { navTitle, route } = {}]) => (
              <NavLink
                key={pageId}
                className={c.concat('Nav__link', 'Nav__link-text')}
                activeClassName={c.concat('Nav__link--active', 'Nav__link-text--active')}
                exact
                to={route}
              >
                {navTitle}
              </NavLink>
            ))}
          </div>
        </div>
      </div>
    </nav>
  );
};
