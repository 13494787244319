import {
  useState,
  useCallback,
} from 'react';
import {
  Tape,
  media,
  compat,
} from '../';





////////////////////////////////////////////////////////////////////////////////
/* Loader hook export */

export default function useLoader() {
  const [loadProgress, setLoadProgress] = useState(compat ? -1 : null);

  const load = useCallback(e => {
    try {
      if (!compat || !Tape || !media.length) {
        throw new Error('unable to load media');
      };
      if (loadProgress < 0) {
        setLoadProgress(0);
        Tape.activate();
        Tape.on('loadprogress', e => setLoadProgress(e.value));
        Tape.load(...media);
      };
    } catch (err) {
      console.error(err);
      setLoadProgress(null);
    };
  }, [loadProgress, setLoadProgress]);

  return {
    load,
    loadProgress,
  };
};
