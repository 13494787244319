




////////////////////////////////////////////////////////////////////////////////
/* Constants */

const numDigits = 5;
const padFrame = 10;
const numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 0];
const widthDigit = 100;





////////////////////////////////////////////////////////////////////////////////
/* Derived constants */

const heightDigit = (widthDigit * 1.5);
const heightDigits = (heightDigit * numbers.length);





////////////////////////////////////////////////////////////////////////////////
/* Parent params */

const params = {
/* bounding box */
  width: (numDigits * widthDigit) + (2 * padFrame),
  height: heightDigit + (2 * padFrame),
};





////////////////////////////////////////////////////////////////////////////////
/* Component params (generic) */

const defs = {
/* constants */
  numbers: numbers,
/* digits */
  xDigit: padFrame,
  yDigit: padFrame,
  widthDigit: widthDigit,
  heightDigit: heightDigit,
/* digit_bg */
  strokeWidthDigitBg: (widthDigit / 100),
/* digit_numbers */
  xDigitNumbers: ((widthDigit / 2) + padFrame),
  styleDigitNumbers: {
    fontFamily: 'Helvetica, sans-serif',
    fontSize: widthDigit,
    fontWeight: 500,
  },
/* frame */
  xFrame: (padFrame / 2),
  yFrame: (padFrame / 2),
  widthFrame: (params.width - padFrame),
  heightFrame: (params.height - padFrame),
  rxFrame: (padFrame * 1.5),
  strokeWidthFrame: padFrame,
/* colors */
  colorFrame: '#a9a9a9',
  colorDigitBgFill: '#222222',
  colorDigitBgStroke: '#000000',
  colorDigitNumbersFill: '#ffffff',
};





////////////////////////////////////////////////////////////////////////////////
/* Helper fns */

const xDigitIndex = (i) => (i * widthDigit);
const yDigitNumbersIndex = (i) => (heightDigit * (i + .5)) + (padFrame * 2);
const transformDigitNumbers = (val) => `translate(0, ${(-heightDigit * val) % heightDigits})`;





////////////////////////////////////////////////////////////////////////////////
/* Export */

export {
  params as default,
  defs,
  xDigitIndex,
  yDigitNumbersIndex,
  transformDigitNumbers,
};
