import {
  memo,
} from 'react';





export default memo(function MechCapstan() {
  return (
    <use xlinkHref="#g__mech-capstan" />
  );
});
