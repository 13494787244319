export default class AudioTapeEvent {

/* ------------------------------------------------------------------ */
/* Private Fields */

  // constants
  static EVENT_TYPES = [
    'mediaoffline',
    'mediaready',
    'loadprogress',
    'load',
    'tick',
    'volumechange',
    'playbackspeedchange',
    'scrubspeedchange',
    'flutterchange',
    'enginestop',
    'enginestart',
    'playbackstatechange',
  ];



/* ------------------------------------------------------------------ */
/* Static Properties */



/* ------------------------------------------------------------------ */
/* Static Methods */



/* ------------------------------------------------------------------ */
/* Getters */



/* ------------------------------------------------------------------ */
/* Private Getters */



/* ------------------------------------------------------------------ */
/* Setters */



/* ------------------------------------------------------------------ */
/* Private Setters */



/* ------------------------------------------------------------------ */
/* Constructor */

  constructor({
    type,
    ctxTime,
    payload = {},
  } = {}) {
    this.type = type;
    this.timestamp = performance.now();
    this.ctxTime = ctxTime;
    if (typeof payload !== 'object') {
      this.value = payload;
    } else {
      Object.assign(this, payload);
    };
  };
};
