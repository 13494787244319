export default class Player {

/* ------------------------------------------------------------------ */
/* Private Fields */

  // Event forwarding
  #emitEvent;

  // Loader forwarding
  #totalLengthSamples;
  #loadSampleData;

  // AudioContext + nodes
  #ctx;
  #masterGain;
  #bufferGain;

  // Fixed constants
  #sampleRate;
  #sampleRateInv;
  #channelCount;
  #chunkLengthKbins;
  #chunkLengthSamples;
  #chunkLengthS;
  #chunkLengthMs;
  #scheduledQueueLength;
  #pendingQueueLength;
  #latencyS;
  #speedQ;
  #speedScalar;

  // Constants exposed via setters
  #playbackSpeed;
  #scrubSpeed;
  #flutter;

  // Engine queues
  #scheduledQueue;
  #pendingQueue;

  // Engine runtime
  #tickMs
  #tickTimeout;
  #tickCb;
  #tickStart;
  #tickCancel;

  // Engine state
  #state;
  #nextState;



/* ------------------------------------------------------------------ */
/* Static Properties */

  // constants
  static KBIN_SAMPLES = 256;

  // default values
  static DEFAULT_SAMPLE_RATE = 48e3;
  static DEFAULT_CHUNK_LENGTH_MS = 20;
  static DEFAULT_LATENCY_MS = 200;
  static DEFAULT_LOOKAHEAD_MS = 10e3;
  static DEFAULT_SPEED_Q = 1;
  static DEFAULT_PLAYBACK_SPEED = 1;
  static DEFAULT_SCRUB_SPEED = 5;
  static DEFAULT_FLUTTER = 0;

  // constraints
  static MIN_PLAYBACK_SPEED = .01;
  static MIN_SPEED_Q = 1;
  static MIN_SPEED_STEP = .01;
  static MIN_FLUTTER = 0;
  static MIN_VOLUME = 0;
  static SPEED_VOLUME_CUTOFF = 1;



/* ------------------------------------------------------------------ */
/* Static Methods */

  static VALID_msToKbins(ms, sampleRate) {
    const seconds = ms * 1e-3;
    const samples = seconds * sampleRate;
    let kbins = Math.round(samples / this.KBIN_SAMPLES);
    if (kbins < 1) {
      kbins = 1;
    };
    return kbins;
  };

  static VALID_msToChunks(ms, chunkLengthMs) {
    let chunks = Math.round(ms / chunkLengthMs);
    if (chunks < 1) {
      chunks = 1;
    };
    return chunks;
  };

  static VALID_clampRange(val, rA, rB) {
    const [minVal, maxVal] = [rA, rB].sort((a, b) => a - b);
    if (val <= minVal) {
      return minVal;
    };
    if (val >= maxVal) {
      return maxVal;
    };
    return val;
  };

  static VALID_clampMin(val, minVal) {
    if (val < minVal) {
      return minVal;
    };
    return val;
  };

  static VALID_clampMax(val, maxVal) {
    if (val > maxVal) {
      return maxVal;
    };
    return val;
  };

  static VALID_clampMagnitudeMin(val, minVal) {
    if (Math.abs(val) < Math.abs(minVal)) {
      return Math.sign(val) * Math.abs(minVal);
    };
    return val;
  };

  static VALID_clampMagnitudeMax(val, maxVal) {
    if (Math.abs(val) > Math.abs(maxVal)) {
      return Math.sign(val) * Math.abs(maxVal);
    };
    return val;
  };



/* ------------------------------------------------------------------ */
/* Getters */

  get sampleRate() {
    return this.#sampleRate;
  };

  get channelCount() {
    return this.#channelCount;
  };

  get chunkLengthSamples() {
    return this.#chunkLengthSamples;
  };

  get totalLengthSamples() {
    return this.#totalLengthSamples;
  };

  get active() {
    return this._ctxActive && this._engineActive;
  };

  get ctxNow() {
    return this.#ctx.currentTime;
  };

  get volume() {
    return this.#masterGain.gain.value;
  };

  get playbackSpeed() {
    return this.#playbackSpeed;
  };

  get scrubSpeed() {
    return this.#scrubSpeed;
  };

  get flutter() {
    return this.#flutter * 1e3;
  };

  get currentSpeed() {
    return this._nowChunk?.playbackSpeed ?? this.#state.targetSpeed;
  };

  // NEEDS OPTIMIZATION
  get playhead() {
    const nowSample = this._nowChunk?.srcSampleIndexStart
      ?? (this.#state.lastPlayedSrcSampleIndex + 1);
    return nowSample * this.#sampleRateInv;
  };

  // get playhead() {
  //   let nowSample;
  //   if (!this.#scheduledQueue.length) {
  //     nowSample = this.#state.lastPlayedSrcSampleIndex + 1;
  //   } else {
  //     const nowChunk = this._nowChunk;
  //     const elapsedSeconds = (this.ctxNow - nowChunk.ctxStartTime);
  //     const elapsedPct = elapsedSeconds / nowChunk.playbackLengthSeconds;
  //     const elapsedSamples = elapsedPct * nowChunk.srcLengthSamples;
  //     nowSample = nowChunk.srcSampleIndexStart + (elapsedSamples * nowChunk.srcSampleIndexShift);
  //   };
  //   return nowSample / this.#sampleRate;
  // };



/* ------------------------------------------------------------------ */
/* Private Getters */

  get _ctxActive() {
    return this.#ctx.state === 'running';
  };

  get _engineActive() {
    return !!this.#tickTimeout;
  };

  get _mediaLoaded() {
    return !!this.#totalLengthSamples && !!this.#loadSampleData;
  };

  get _nowChunk() {
    return this.#scheduledQueue[0];
  };

  get _lastChunk() {
    return this._lastPendingChunk ?? this._lastScheduledChunk;
  };

  get _lastScheduledChunk() {
    return this.#scheduledQueue[this.#scheduledQueue.length - 1];
  };

  get _lastPendingChunk() {
    return this.#pendingQueue[this.#pendingQueue.length - 1];
  };

  get _playheadAtEnd() {
    return this.#state.lastPlayedSrcSampleIndex >= (this.#totalLengthSamples - 1);
  };

  get _playheadAtStart() {
    return this.#state.lastPlayedSrcSampleIndex <= 0
  };

  get _gainNode() {
    return this.#masterGain;
  };



/* ------------------------------------------------------------------ */
/* Setters */

  set volume(n) {
    const safeVal = this.constructor.VALID_clampMin(n, this.constructor.MIN_VOLUME);
    const now = this.ctxNow;
    const rampEnd = now + this.#chunkLengthS;
    this.#masterGain.gain.cancelScheduledValues(now);
    this.#masterGain.gain.linearRampToValueAtTime(safeVal, rampEnd);
    this.#emitEvent('volumechange', this.#masterGain.gain.value);
  };

  set playbackSpeed(n) {
    if (n >= this.#scrubSpeed) return null;
    this.#playbackSpeed = this._clampSpeed(Math.abs(n));
    this.#emitEvent('playbackspeedchange', this.#playbackSpeed);
    if (this.#state.isPlaying === 1) {
      const nextTargetSpeed = Math.sign(this.#state.targetSpeed) * this.#playbackSpeed;
      this._state_set({ targetSpeed: nextTargetSpeed });
    };
  };

  set scrubSpeed(n) {
    if (n <= this.#playbackSpeed) return null;
    this.#scrubSpeed = this._clampSpeed(Math.abs(n));
    this.#emitEvent('scrubspeedchange', this.#scrubSpeed);
    if (this.#state.isPlaying === 2) {
      const nextTargetSpeed = Math.sign(this.#state.targetSpeed) * this.#scrubSpeed;
      this._state_set({ targetSpeed: nextTargetSpeed });
    };
  };

  set flutter(n) {
    this.#flutter = this.constructor.VALID_clampMin(n, this.constructor.MIN_FLUTTER) * 1e-3;
    this.#emitEvent('flutterchange', this.#flutter);
  };



/* ------------------------------------------------------------------ */
/* Private Setters */

  set _totalLengthSamples(n) {
    this.#totalLengthSamples = n;
  };

  set _loadSampleDataCb(fn) {
    this.#loadSampleData = fn;
  };



/* ------------------------------------------------------------------ */
/* Constructor */

  constructor({
    /* Fixed at instantiation */
    sampleRate = this.constructor.DEFAULT_SAMPLE_RATE,
    chunkLengthMs = this.constructor.DEFAULT_CHUNK_LENGTH_MS,
    latencyMs = this.constructor.DEFAULT_LATENCY_MS,
    lookaheadMs = this.constructor.DEFAULT_LOOKAHEAD_MS,
    speedQ = this.constructor.DEFAULT_SPEED_Q,
    /* Exposed via getters/setters */
    playbackSpeed = this.constructor.DEFAULT_PLAYBACK_SPEED,
    scrubSpeed = this.constructor.DEFAULT_SCRUB_SPEED,
    flutter = this.constructor.DEFAULT_FLUTTER,
    /* Internal */
    eventEmitter,
  } = {}) {
    /* Event forwarding */
    this.#emitEvent = eventEmitter;
    /* Loader forwarding */
    this.#totalLengthSamples = 0;
    this.#loadSampleData = null;
    /* AudioContext instantiation */
    this.#ctx = new AudioContext({ sampleRate });
    /* GainNode instantiation */
    this.#masterGain = this.#ctx.createGain();
    this.#masterGain.connect(this.#ctx.destination);
    this.#masterGain.gain.value = 1;
    this.#bufferGain = this.#ctx.createGain();
    this.#bufferGain.connect(this.#masterGain);
    this.#bufferGain.gain.value = 1;
    /* Fixed constants */
    this.#sampleRate = this.#ctx.sampleRate;
    this.#sampleRateInv = 1 / this.#sampleRate;
    this.#channelCount = this.#ctx.destination.channelCount;
    this.#chunkLengthKbins = this.constructor.VALID_msToKbins(chunkLengthMs, this.#sampleRate);
    this.#chunkLengthSamples = this.#chunkLengthKbins * this.constructor.KBIN_SAMPLES;
    this.#chunkLengthS = this.#chunkLengthSamples / this.#sampleRate;
    this.#chunkLengthMs = this.#chunkLengthS * 1e3;
    this.#scheduledQueueLength = this.constructor.VALID_msToChunks(latencyMs, this.#chunkLengthMs);
    this.#pendingQueueLength = this.constructor.VALID_msToChunks(lookaheadMs, this.#chunkLengthMs);
    this.#latencyS = this.#scheduledQueueLength * this.#chunkLengthS;
    this.#speedQ = this.constructor.VALID_clampMin(speedQ, this.constructor.MIN_SPEED_Q);
    this.#speedScalar = 1 / (this.#scheduledQueueLength ** (this.#speedQ + 1));
    /* Properties exposed via getters/setters */
    this.#playbackSpeed = this._clampSpeed(playbackSpeed);
    this.#scrubSpeed = this._clampSpeed(scrubSpeed);
    this.#flutter = this.constructor.VALID_clampMin(flutter, this.constructor.MIN_FLUTTER) * 1e-3;
    /* Engine queues */
    this.#scheduledQueue = [];
    this.#pendingQueue = [];
    /* Engine runtime */
    this.#tickMs = Math.floor(this.#chunkLengthMs / 2);
    this.#tickTimeout = null;
    this.#tickCb = this._tickCb.bind(this);
    this.#tickStart = this._tickStart.bind(this);
    this.#tickCancel = this._tickCancel.bind(this);
    /* Engine state */
    this.#state = {
      lastPlayedSrcSampleIndex: -1,
      lastPlayedPlaybackSpeed: 0,
      isPlaying: false,
      targetSpeed: 0,
    };
    this.#nextState = {};
    /* Public methods -- engine */
    this.activate = this.activate.bind(this);
    this.deactivate = this.deactivate.bind(this);
    /* Public methods -- transport */
    this.stop = this.stop.bind(this);
    this.play = this.play.bind(this);
    this.rev = this.rev.bind(this);
    this.ff = this.ff.bind(this);
    this.rew = this.rew.bind(this);
  };



/* ------------------------------------------------------------------ */
/* Public methods -- engine */

  activate() {
    if (!this._ctxActive) {
      this.#ctx.resume();
    };
    if (!this._engineActive) {
      this.#tickStart();
    };
  };

  deactivate() {
    if (this._ctxActive) {
      this.#ctx.suspend();
    };
    if (this._engineActive) {
      this.#tickCancel();
    };
  };



/* ------------------------------------------------------------------ */
/* Public methods -- transport */

  stop() {
    this._state_set({
      isPlaying: 0,
      targetSpeed: 0,
    });
  };

  play() {
    if (this._playheadAtEnd) {
      return null;
    };
    this._state_set({
      isPlaying: 1,
      targetSpeed: this.#playbackSpeed,
    });
  };

  rev() {
    if (this._playheadAtStart) {
      return null;
    };
    this._state_set({
      isPlaying: 1,
      targetSpeed: -this.#playbackSpeed,
    });
  };

  ff() {
    if (this._playheadAtEnd) {
      return null;
    };
    this._state_set({
      isPlaying: 2,
      targetSpeed: this.#scrubSpeed,
    });
  };

  rew() {
    if (this._playheadAtStart) {
      return null;
    };
    this._state_set({
      isPlaying: 2,
      targetSpeed: -this.#scrubSpeed,
    });
  };



/* ------------------------------------------------------------------ */
/* Transport state management + helpers */

/* enqueue state change to nextState */
   _state_set(stateObj = {}) {
    if (!this._mediaLoaded) {
      return null;
    };
    Object.assign(this.#nextState, stateObj);
    if (!this._engineActive) {
      this.#tickStart();
    };
   };


/* read enqueued nextState + apply to state + trigger side effects */
  _state_apply() {
    const nextStateEntries = Object.entries({ ...this.#nextState });    // clone next state object + get entries to update
    if (!nextStateEntries.length) return null;
    this.#nextState = {};   // reset next state
    const deltaState = {};
    for (const [key, val] of nextStateEntries) {    // determine delta + trigger side effects
      if (this.#state[key] === val) continue;
      switch (key) {
        case 'lastPlayedSrcSampleIndex':
          deltaState.lastPlayedSrcSampleIndex = val;
          if (val <= 0 || val === (this.#totalLengthSamples - 1)) {
            this.stop();
          };
          break;
        case 'lastPlayedPlaybackSpeed':
          deltaState.lastPlayedPlaybackSpeed = val;
          break;
        case 'isPlaying':
          deltaState.isPlaying = val;
          this.#emitEvent('playbackstatechange', { isPlaying: val });
          break;
        case 'targetSpeed':
          deltaState.targetSpeed = val;
          this._queue_clearPending();
          break;
        default:
          break;
      };
    };
    if (Object.keys(deltaState).length) {   // overwrite current state
      this.#state = Object.assign({ ...this.#state }, deltaState);
    };
  };



/* ------------------------------------------------------------------ */
/* Engine tick callback */

/* engine runtime */
  _tickCb() {
    // this.#emitEvent('tick', { playhead: this.playhead });
    this._state_apply();
    if (this.#state.targetSpeed === 0 && !this.#scheduledQueue.length) {
      this.#tickCancel();
      return null;
    };
    this.#tickTimeout = setTimeout(this.#tickCb, this.#tickMs);
    const nextCtxStartTime = this._nowChunk?.nextCtxStartTime ?? 0;
    const currentTime = this.ctxNow;
    if (nextCtxStartTime > currentTime) {
      return null;
    };
    this._queue_refillScheduled(currentTime);
    this._queue_refillPending();
    this._queue_pruneScheduled(currentTime);
  };


/* engine timeout set */
  _tickStart() {
    clearTimeout(this.#tickTimeout);
    this.#tickTimeout = setTimeout(this.#tickCb, this.#tickMs);
    this.#emitEvent('enginestart', { playhead: this.playhead });
  };


/* engine timeout cancel */
  _tickCancel() {
    clearTimeout(this.#tickTimeout);
    this.#tickTimeout = null;
    this.#emitEvent('enginestop', { playhead: this.playhead });
  };



/* ------------------------------------------------------------------ */
/* Queue scheduling + helpers  */

/* shift chunks from pending into scheduled queue + schedule for playback */
  _queue_refillScheduled(ctxTime) {
    if (!this.#pendingQueue.length) return null;
    let ctxStartTime = this._lastScheduledChunk?.nextCtxStartTime
      ?? this._clampClock(ctxTime + this.#latencyS);
    let nextChunk;
    while (this.#scheduledQueue.length < this.#scheduledQueueLength) {
      nextChunk = this.#pendingQueue.shift();
      if (!nextChunk) break;
      const nextScheduledChunk = this._chunk_schedule(nextChunk, ctxStartTime);
      this.#scheduledQueue.push(nextScheduledChunk);
      ctxStartTime = nextScheduledChunk.nextCtxStartTime;
    };
  };


/* remove chunks that have finished playing from top of scheduled queue */
  _queue_pruneScheduled(ctxTime) {
    if (!this.#scheduledQueue.length) return null;
    let nextCtxStartTime = this.#scheduledQueue[0].nextCtxStartTime;
    let lastPlayedChunk;
    while (nextCtxStartTime < ctxTime) {
      lastPlayedChunk = this.#scheduledQueue.shift();
      if (!lastPlayedChunk) break;
      this._state_set({
        lastPlayedSrcSampleIndex: lastPlayedChunk.srcSampleIndexEnd,
        lastPlayedPlaybackSpeed: lastPlayedChunk.playbackSpeed,
      });
      nextCtxStartTime = lastPlayedChunk.nextCtxStartTime;
    };
  };


/* generate new chunks + refill end of pending queue */
  _queue_refillPending() {
    const lastChunk = this._lastChunk;
    if (!lastChunk && !this.#state.isPlaying) return null;
    let lastSrcSampleIndexEnd = lastChunk?.srcSampleIndexEnd
      ?? this.#state.lastPlayedSrcSampleIndex;
    let lastPlaybackSpeed = lastChunk?.playbackSpeed ?? 0;
    let lastSrcSampleIndexShift = lastChunk?.lastSrcSampleIndexShift
      ?? Math.sign(lastPlaybackSpeed);
    while (this.#pendingQueue.length < this.#pendingQueueLength) {
      const srcSampleIndexStart = this._clampSample(lastSrcSampleIndexEnd + lastSrcSampleIndexShift);
      const playbackSpeed = this._queue_calcNextSpeed(lastPlaybackSpeed, this.#state.targetSpeed);
      if (playbackSpeed === 0 && !this.#state.isPlaying) {
        return null;
      };
      const nextChunk = this._chunk_create(srcSampleIndexStart, playbackSpeed);
      if (!nextChunk) break;
      this.#pendingQueue.push(nextChunk);
      lastSrcSampleIndexEnd = nextChunk.srcSampleIndexEnd;
      lastPlaybackSpeed = nextChunk.playbackSpeed;
      lastSrcSampleIndexShift = nextChunk.srcSampleIndexShift;
    };
  };


/* depopulate pending queue */
  _queue_clearPending() {
    this.#pendingQueue.splice(0, this.#pendingQueue.length);
  };


/* schedule gain change */
  _queue_scheduleGain(targetVal, ctxTime) {
    this.#bufferGain.gain.linearRampToValueAtTime(targetVal, ctxTime);
  };


/* calculate speed of next chunk based on previous, target + step */
  _queue_calcNextSpeed(lastSpeed, targetSpeed, scalar = this.#speedScalar) {
    if (lastSpeed === targetSpeed) {
      return targetSpeed;
    };
    const speedStep = Math.sqrt((lastSpeed ** 2) + 1) * scalar;
    let speedDelta = (targetSpeed - lastSpeed) * speedStep;
    if (Math.abs(speedDelta) <= this.constructor.MIN_SPEED_STEP) {
      speedDelta = Math.sign(speedDelta) * this.constructor.MIN_SPEED_STEP;
    };
    const nextSpeed = lastSpeed + speedDelta;
    return this.constructor.VALID_clampRange(nextSpeed, lastSpeed, targetSpeed);
  };



/* ------------------------------------------------------------------ */
/* Chunk creation + helpers  */

/* return new playback chunk object and populate buffer with sample data */
  _chunk_create(startSample, speed) {
    const {
      sampleDataByChannel,
      srcLengthSamples,
      ...chunkData
    } = this.#loadSampleData(startSample, speed);
    const buffer = this._chunk_getBuffer(srcLengthSamples, sampleDataByChannel);
    if (buffer.length <= 1) return null;
    return {
      buffer,
      srcLengthSamples,
      ...chunkData,
    };
  };


/* schedule chunk for playback and assign values to returned chunk object */
  _chunk_schedule(chunk = {}, ctxStartTime = 0) {
    chunk.ctxStartTime = ctxStartTime;
    chunk.node = this.#ctx.createBufferSource();
    chunk.node.buffer = chunk.buffer;
    chunk.node.playbackRate.value = Math.abs(chunk.playbackSpeed * this._chunk_genFlutter());
    chunk.node.connect(this.#bufferGain);
    chunk.node.start(chunk.ctxStartTime, 0);
    chunk.playbackLengthSeconds = (chunk.node.buffer.duration / chunk.node.playbackRate.value);
    chunk.nextCtxStartTime = (chunk.ctxStartTime + chunk.playbackLengthSeconds);
    chunk.playbackSpeed = chunk.node.playbackRate.value * chunk.srcSampleIndexShift;
    chunk.gain = this._chunk_calcGain(chunk.node.playbackRate.value);
    this._queue_scheduleGain(chunk.gain, chunk.nextCtxStartTime);
    return chunk;
  };


/* return new buffer populated populate with channel sample data */
  _chunk_getBuffer(srcLengthSamples = 0, sampleDataByChannel = []) {
    const buffer = this.#ctx.createBuffer(this.#channelCount, srcLengthSamples, this.#sampleRate);
    for (const channelIndex in sampleDataByChannel) {
      buffer.copyToChannel(sampleDataByChannel[channelIndex], channelIndex);
    };
    return buffer;
  };


/* calculate gain of chunk */
  _chunk_calcGain(absSpeed, cutoff = this.constructor.SPEED_VOLUME_CUTOFF) {
    const cutoffSpeed = Math.min(cutoff, this.#playbackSpeed);
    if (absSpeed >= cutoffSpeed) return 1;
    const targetGain = (absSpeed - this.constructor.MIN_PLAYBACK_SPEED) / cutoffSpeed;
    return this.constructor.VALID_clampMin(targetGain, 0);
  };


/* generate random flutter value */
  _chunk_genFlutter() {
    return 1 + (this.#flutter * (Math.random() - .5));
  };


/* ------------------------------------------------------------------ */
/* Misc helpers */

/* clamp seconds value to whole-sample equivalent float */
  _clampClock(seconds) {
    const timeSamples = seconds * this.#sampleRate;
    const timeSamplesWhole = Math.round(timeSamples);
    return timeSamplesWhole / this.#sampleRate;
  };


/* clamp speed value to whole-sample equivalent float */
  _clampSpeed(speed) {
    const safeSpeed = this.constructor.VALID_clampMagnitudeMin(speed, this.constructor.MIN_PLAYBACK_SPEED);
    const srcChunkSamples = this.#chunkLengthSamples * safeSpeed;
    const targetSrcChunkSamples = Math.round(srcChunkSamples);
    return targetSrcChunkSamples / this.#chunkLengthSamples;
  };


/* clamp arbitrary sample value to integer within available range */
  _clampSample(sample) {
    if (sample < 0) {
      return 0;
    };
    if (sample >= this.#totalLengthSamples) {
      return this.#totalLengthSamples - 1;
    };
    return parseInt(sample, 10);
  };



};
