export default class Loader {

/* ------------------------------------------------------------------ */
/* Private Fields */

  // Event forwarding
  #emitEvent;

  // AudioContext
  #ctx;

  // Fixed constants
  #sampleRate;
  #channelCount;
  #chunkLengthSamples;

  // Media
  #audioData;
  #totalLengthSamples;



/* ------------------------------------------------------------------ */
/* Static Properties */



/* ------------------------------------------------------------------ */
/* Static Methods */



/* ------------------------------------------------------------------ */
/* Getters */



/* ------------------------------------------------------------------ */
/* Private Getters */



/* ------------------------------------------------------------------ */
/* Setters */



/* ------------------------------------------------------------------ */
/* Private Setters */



/* ------------------------------------------------------------------ */
/* Constructor */

  constructor({
    sampleRate = 48e3,
    channelCount = 2,
    chunkLengthSamples = 1024,
    /* Internal */
    eventEmitter,
  } = {}) {
    /* Event forwarding */
    this.#emitEvent = eventEmitter;
    /* AudioContext instantiation */
    this.#ctx = new OfflineAudioContext(channelCount, sampleRate, sampleRate);
    /* Fixed constants */
    this.#sampleRate = this.#ctx.sampleRate;
    this.#channelCount = channelCount;
    this.#chunkLengthSamples = chunkLengthSamples;
    /* Media */
    this.#audioData = [];   // [[pointer(start sample index), ch1Samples, ch2Samples, ...]]
    this.#totalLengthSamples = 0;
    /* Private methods -- bound */
    this._load_parseArgs = this._load_parseArgs.bind(this);
    this._load_srcToAudioDatum = this._load_srcToAudioDatum.bind(this);
    /* Public methods -- media */
    this.load = this.load.bind(this);
    this.unload = this.unload.bind(this);
    /* Public methods -- engine */
    this.getSampleData = this.getSampleData.bind(this);
  };



/* ------------------------------------------------------------------ */
/* Engine -- public methods */

/*  */
  getSampleData(sampleIndexStart, speed = 1) {
    const {
      srcSampleIndexShift,
      srcLengthSamples,
      outputLengthSamples,
      sampleIndexRange,
      reverse,
    } = this._parseSampleRange(sampleIndexStart, speed);

    const sampleDataByChannel = this._getSrcSampleData(sampleIndexRange, srcLengthSamples, reverse);

    return {
      srcSampleIndexStart: sampleIndexRange[0],
      srcSampleIndexEnd: sampleIndexRange[1],
      srcSampleIndexShift,
      srcLengthSamples,
      playbackLengthSamples: outputLengthSamples,
      playbackSpeed: (srcLengthSamples / outputLengthSamples) * srcSampleIndexShift,
      sampleDataByChannel: sampleDataByChannel,
    };
  };



/* sanitize input arguments from getSampleData() and return object of sample extraction constants */
  _parseSampleRange(sampleIndexStart, speed = 1) {
    const srcSampleIndexShift = Math.sign(speed);
    const targetSrcLengthSamples = Math.round(this.#chunkLengthSamples * speed);
    const sampleIndexEnd = sampleIndexStart + (targetSrcLengthSamples - srcSampleIndexShift);

    const clampedSampleIndexStart = this._clampSampleIndex(sampleIndexStart);
    const clampedSampleIndexEnd = this._clampSampleIndex(sampleIndexEnd);
    const srcLengthSamples = Math.abs(clampedSampleIndexEnd - clampedSampleIndexStart) + 1;

    let outputLengthSamples = this.#chunkLengthSamples;
    if (srcLengthSamples < targetSrcLengthSamples) {
      const underflow = (targetSrcLengthSamples - srcLengthSamples);
      const underflowPct = (underflow / targetSrcLengthSamples);
      outputLengthSamples = Math.round(this.#chunkLengthSamples * (1 - underflowPct));
    };

    return {
      srcSampleIndexShift,
      srcLengthSamples,
      outputLengthSamples,
      sampleIndexRange: [
        clampedSampleIndexStart,
        clampedSampleIndexEnd,
      ],
      reverse: clampedSampleIndexStart > clampedSampleIndexEnd,
    };
  };


/* accepts integer + returns clamped integer as valid index in audio sample data */
  _clampSampleIndex(sampleIndex) {
    let clampedSampleIndex = sampleIndex;
    if (sampleIndex < 0) {
      clampedSampleIndex = 0;
    };
    if (sampleIndex > (this.#totalLengthSamples - 1)) {
      clampedSampleIndex = (this.#totalLengthSamples - 1);
    };
    return clampedSampleIndex;
  };


/* return array of per-channel audio samples as Float32Arrays matching playback direction */
  _getSrcSampleData(sampleIndexRange, srcLengthSamples, reverse) {
    const lookupSampleIndexRange = [...sampleIndexRange];
    if (reverse) {
      lookupSampleIndexRange.reverse();
    };
    const audioDatumSampleRanges = this._getAudioDatumSampleRanges(lookupSampleIndexRange);
    const sampleDataByChannel = this._getJoinedSampleDataSlice(audioDatumSampleRanges, srcLengthSamples);

    if (reverse) {
      for (const channel in sampleDataByChannel) {
        sampleDataByChannel[channel] = new Float32Array(sampleDataByChannel[channel]).reverse();
      };
    };
    return sampleDataByChannel;
  };


/* return array of audioDatumIndex + range of relative subsample indicies */
  _getAudioDatumSampleRanges([sampleIndexStart, sampleIndexEnd] = []) {
    const audioDatumIndex = this.#audioData.findIndex(d => sampleIndexStart <= d.sampleIndexEnd);
    const audioDatumSampleIndexEnd = this.#audioData[audioDatumIndex].sampleIndexEnd;
    if (sampleIndexEnd > audioDatumSampleIndexEnd) {
      return [
        this._getAudioDatumSampleRange(audioDatumIndex, [sampleIndexStart, audioDatumSampleIndexEnd]),
        this._getAudioDatumSampleRange(audioDatumIndex + 1, [audioDatumSampleIndexEnd + 1, sampleIndexEnd]),
      ];
    };
    return [
      this._getAudioDatumSampleRange(audioDatumIndex, [sampleIndexStart, sampleIndexEnd]),
    ];
  };


/* map absolute sample indicies to relative inticies within audioDatum */
  _getAudioDatumSampleRange(audioDatumIndex, sampleIndexRange) {
    return [
      audioDatumIndex,
      sampleIndexRange.map(s => s - this.#audioData[audioDatumIndex].sampleIndexStart),
    ]
  };


/* return array of per-channel audio samples as Float32Arrays */
  _getJoinedSampleDataSlice(audioDatumSampleRanges = [], outputLengthSamples) {
    const sampleDataByChannel = new Array(this.#channelCount)
      .fill(new Float32Array(outputLengthSamples));
    for (let channelIndex = 0; channelIndex < this.#channelCount; channelIndex++) {
      let outputSampleIndex = 0;
      for (const [audioDatumIndex, sampleIndexRange] of audioDatumSampleRanges) {
        const channelSampleData = this.#audioData[audioDatumIndex].data?.[channelIndex];
        if (!channelSampleData) {
          outputSampleIndex += (sampleIndexRange[1] - sampleIndexRange[0]) + 1;
          continue;
        };
        const channelSampleDataSlice = channelSampleData.subarray(sampleIndexRange[0], sampleIndexRange[1] + 1);
        sampleDataByChannel[channelIndex].set(channelSampleDataSlice, outputSampleIndex);
        outputSampleIndex += channelSampleDataSlice.length;
      };
    };
    return sampleDataByChannel;
  };



/* ------------------------------------------------------------------ */
/* Media -- public methods */

/*  */
  async load(...loadArgs) {
    const loadQueue = this._load_parseArgs([...loadArgs]);
    let i = 0;
    let pointer = 0;
    while (i < loadQueue.length) {
      const audioDatum = await this._load_srcToAudioDatum(loadQueue[i++]);
      if (audioDatum) {
        const sampleIndexStart = this.#totalLengthSamples + pointer;
        const [length, ...channelSampleData] = audioDatum;
        pointer += length;
        this.#audioData.push({
          sampleIndexStart,
          sampleIndexEnd: sampleIndexStart + (length - 1),
          sampleLength: length,
          data: [...channelSampleData],
        });
        this.#emitEvent('loadprogress', (i / loadQueue.length));
      };
    };
    this.#emitEvent('load', { samplesLoaded: pointer });
    this.#totalLengthSamples += pointer;
    this.#emitEvent('mediaready', { totalLengthSamples: this.#totalLengthSamples });
  };


/*  */
  unload() {
    this.#emitEvent('mediaoffline');
    this.#totalLengthSamples = 0;
    this.audioData = [];
  };



/* ------------------------------------------------------------------ */
/* Media -- helpers */

/* parse load arguments + return shallow array copy of load sources */
  _load_parseArgs(loadArgs = []) {
    if (!loadArgs.length) {
      throw new SyntaxError('No load arguments');
    };
    if (loadArgs.length === 1 && Array.isArray(loadArgs[0])) {
      return this._load_parseArgs(loadArgs[0]);
    };
    return [...loadArgs];
  };


/* accept source argument + return parsed sample data as [length<Number:int>[, ...channelSamples<Float32Array>]] */
  _load_srcToAudioDatum(src) {
    switch (true) {
      case (typeof src === 'number'):
        return this._load_fromSilence(src);
      case (src instanceof AudioBuffer):
        return this._load_fromAudioBuffer(src);
      case (typeof src === 'string'):
        return this._load_fromUrl(src);
      case (src instanceof Blob):
        return this._load_fromBlob(src);
      default:
        throw new TypeError('Invalid load source', src);
    };
  };


/*  */
  _load_fromSilence(seconds = 0) {
    const silentSamples = Math.floor(seconds * this.#sampleRate);
    return [silentSamples];
  };


/*  */
  _load_fromAudioBuffer(audioBuffer) {
    try {
      const data = [audioBuffer.length];
      for (let i = 0; i < audioBuffer.numberOfChannels; i++) {
        data[i + 1] = audioBuffer.getChannelData(i);
      };
      return data;
    } catch (err) {
      console.error('Unable to load AudioBuffer:', audioBuffer);
      console.error(err);
      return null;
    };
  };


/*  */
  async _load_fromBlob(blob) {
    try {
      const arrayBuffer = await blob.arrayBuffer();
      const audioBuffer = await this.#ctx.decodeAudioData(arrayBuffer);
      return this._load_fromAudioBuffer(audioBuffer);
    } catch (err) {
      console.error('Unable to load Blob:', blob);
      console.error(err);
      return null;
    };
  };


/*  */
  async _load_fromUrl(url = '') {
    try {
      const res = await fetch(url);
      const arrayBuffer = await res.arrayBuffer();
      const audioBuffer = await this.#ctx.decodeAudioData(arrayBuffer);
      return this._load_fromAudioBuffer(audioBuffer);
    } catch (err) {
      console.error('Unable to load url:', url);
      console.error(err);
      return null;
    };
  };



};
