import {
  useRef,
  useEffect,
} from 'react';
import layer00 from './_img/00_backpane.svg';
import layer01_L from './_img/01_label-left.svg';
import layer01_R from './_img/01_label-right.svg';
import layer02_OFF from './_img/02_led-off.svg';
import layer02_ON from './_img/02_led-on.svg';
import layer03 from './_img/03_needle-shadow.svg';
import layer04 from './_img/04_needle.svg';
import layer05 from './_img/05_frame.png';





////////////////////////////////////////////////////////////////////////////////
/* Canvas helpers */

async function getImg(src = '')  {
  if (!src) return null;
  const img = new Image();
  try {
    img.src = src;
    await img.decode();
    return img;
  } catch (err) {
    console.error(err);
  } finally {
    return img;
  };
};


function getOffscreenCanvas(width, height) {
  let canvas;
  if (typeof OffscreenCanvas !== 'undefined') {
    canvas = new OffscreenCanvas(width, height);
  } else {
    canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
  };
  return canvas;
};


async function getImageCanvas(src = '') {
  const img = await getImg(src);
  const { width, height } = img;
  const canvas = getOffscreenCanvas(width, height);
  const ctx = canvas.getContext('2d');
  void ctx.drawImage(img, 0, 0, width, height);
  return canvas;
};


function combineCanvases(...canvases) {
  const { width, height } = canvases
    .reduce((acc, { width, height }) => {
      if (width > acc.width) {
        acc.width = width;
      };
      if (height > acc.height) {
        acc.height = height;
      };
      return acc;
    }, { width: 0, height: 0 });
  const canvas = getOffscreenCanvas(width, height);
  const ctx = canvas.getContext('2d');
  canvases.forEach(c => {
    void ctx.drawImage(c, 0, 0, width, height);
  });
  return canvas;
};





////////////////////////////////////////////////////////////////////////////////
/* Image load fns */

async function preload() {
  const layers = {
    layer00,
    layer01_L,
    layer01_R,
    layer02_OFF,
    layer02_ON,
    layer03,
    layer04,
    layer05,
  };

  const canvii = {};
  for (const layer in layers) {
    const img = layers[layer];
    canvii[layer] = await getImageCanvas(img);
  };

  const sharedLayers = {
    shadow: canvii.layer03,
    needle: canvii.layer04,
    frame: canvii.layer05,
  };
  const bgLabelLeft = combineCanvases(canvii.layer00, canvii.layer01_L);
  const bgLabelRight = combineCanvases(canvii.layer00, canvii.layer01_R);

  const out = {};
  out.L = {
    bgLedOff: combineCanvases(bgLabelLeft, canvii.layer02_OFF),
    bgLedOn: combineCanvases(bgLabelLeft, canvii.layer02_ON),
    ...sharedLayers,
  };
  out.R = {
    bgLedOff: combineCanvases(bgLabelRight, canvii.layer02_OFF),
    bgLedOn: combineCanvases(bgLabelRight, canvii.layer02_ON),
    ...sharedLayers,
  };

  return out;
};



function _get_getCanviiBySide() {
  let resL,
      resR;
  const out = {
    L: new Promise(res => resL = res),
    R: new Promise(res => resR = res),
  };
  preload()
    .then(c => {
      resL(c.L);
      resR(c.R);
    });

  return function(side = '') {
    return out[side];
  };
};


const getCanviiBySide = _get_getCanviiBySide();



async function getCanvii(side = '', width = 1, height = 1) {
  const canvii = await getCanviiBySide(side);

  const outCanvii = {};
  for (const cKey in canvii) {
    const outCanvas = getOffscreenCanvas(width, height);
    const ctx = outCanvas.getContext('2d');
    ctx.drawImage(canvii[cKey], 0, 0, width, height);
    outCanvii[cKey] = outCanvas;
  };

  return outCanvii;
};





////////////////////////////////////////////////////////////////////////////////
/* Draw class */

class MeterDraw {

  static DEFAULT_ROTATION = -48;
  static DEFAULT_PEAK = false;

  static DEG_SCALAR = (Math.PI / 180);
  static DEG_MEMO = new Map();
  static DEG_TO_RAD(deg) {
    let rad = this.DEG_MEMO.get(deg);
    if (!rad) {
      rad = +(deg * this.DEG_SCALAR).toFixed(2);
      this.DEG_MEMO.set(deg, rad);
    };
    return rad;
  };


  set canvasEl(c) {
    this.initialize(c);
  };


  get canvasEl() {
    return this.canvas;
  };

  get ready() {
    return !!this.srcCanvii && !!this.canvas;
  };


  constructor(side, scalar = 4) {
    this.side = side;
    this.scalar = scalar;
    this.canvas = null;
    this.offCanvas = null;
    this.width = 1;
    this.height = 1;
    this.ctx = null;
    this.offCtx = null;
    this.needleScaleX = 0;
    this.needleScaleY = 0;
    this.shadowScalarX = 0;
    this.shadowScalarY = 0;
    this.shadowDeltaX = 0;
    this.shadowDeltaY = 0;
    this.shadowScalarCache = null;
    this.srcCanvii = null;
    this.draw = this.draw.bind(this);
  };


  initialize(canvas) {
    this.canvas = canvas;
    this.offCanvas = getOffscreenCanvas(1, 1);
    const { clientWidth, clientHeight } = this.canvas;
    this.width = Math.round(clientWidth * this.scalar);
    this.height = Math.round(clientHeight * this.scalar);
    this.ctx = this.canvas.getContext('2d');
    this.ctx.imageSmoothingEnabled = true;
    this.offCtx = this.offCanvas.getContext('2d');
    this.offCtx.imageSmoothingEnabled = true;
    this.canvas.width = this.width;
    this.canvas.height = this.height;
    this.offCanvas.width = this.width;
    this.offCanvas.height = this.height;
    this.needleScaleX = this.width * .5;
    this.needleScaleY = this.height * .95;
    this.shadowScalarX = this.width * this.constructor.DEG_SCALAR * 1e-4;
    this.shadowScalarY = this.height * this.constructor.DEG_SCALAR * 2e-4;
    this.shadowDeltaX = this.width * .5;
    this.shadowDeltaY = this.height * .97;
    this.shadowScalarCache = new Map();
    this.setSrcCanvii(this.side, this.width, this.height)
      .then(this.draw);
  };

  setSrcCanvii(side, width, height) {
    return getCanvii(side, width, height)
      .then(canvii => {
        this.srcCanvii = canvii;
      });
  };

  getShadowScalars(rad) {
    let scalars = this.shadowScalarCache.get(rad);
    if (!scalars) {
      scalars = [
        (this.shadowScalarX * rad) + this.shadowDeltaX,
        (this.shadowScalarY * rad) + this.shadowDeltaY,
      ];
      this.shadowScalarCache.set(rad, scalars);
    };
    return scalars;
  };


  draw_reset() {
    this.ctx.clearRect(0, 0, this.width, this.height);
    this.offCtx.clearRect(0, 0, this.width, this.height);
  };

  draw_apply() {
    this.ctx.drawImage(this.offCanvas, 0, 0, this.width, this.height);
  };

  drawBg(peak) {
    const layerCanvas = peak
      ? this.srcCanvii.bgLedOn
      : this.srcCanvii.bgLedOff;
    this.offCtx.drawImage(layerCanvas, 0, 0, this.width, this.height);
  };

  drawShadow(rad) {
    const [
      shadowScaleX,
      shadowScaleY,
    ] = this.getShadowScalars(rad);
    this.offCtx.translate(shadowScaleX, shadowScaleY);
    this.offCtx.rotate(rad);
    this.offCtx.translate(-shadowScaleX, -shadowScaleY);
    this.offCtx.drawImage(this.srcCanvii.shadow, 0, 0, this.width, this.height);
    this.offCtx.setTransform(1, 0, 0, 1, 0, 0);
  };

  drawNeedle(rad) {
    this.offCtx.translate(this.needleScaleX, this.needleScaleY);
    this.offCtx.rotate(rad);
    this.offCtx.translate(-this.needleScaleX, -this.needleScaleY);
    this.offCtx.drawImage(this.srcCanvii.needle, 0, 0, this.width, this.height);
    this.offCtx.setTransform(1, 0, 0, 1, 0, 0);
  };

  drawFrame() {
    this.offCtx.drawImage(this.srcCanvii.frame, 0, 0, this.width, this.height);
  };


  draw(rotation = this.constructor.DEFAULT_ROTATION, peak = this.constructor.DEFAULT_PEAK) {
    if (!this.ready) return null;
    const rad = this.constructor.DEG_TO_RAD(+rotation.toFixed(2));
    this.draw_reset();
    this.drawBg(peak);
    this.drawShadow(rad);
    this.drawNeedle(rad);
    this.drawFrame();
    this.draw_apply();
  };

};





////////////////////////////////////////////////////////////////////////////////
/* Export hook */

export default function useMeterCanvas({
  side = '',
  rotation = -48,
  peak = false,
} = {}) {
  const drawRef = useRef(null);
  const canvasRef = useRef(null);

  useEffect(() => {     // initialize draw class;
    if (!drawRef.current) {
      drawRef.current = new MeterDraw(side);
    };
  }, [side, drawRef]);

  useEffect(() => {     // set canvas;
    if (!!canvasRef.current && !drawRef?.current?.canvasEl) {
      drawRef.current.canvasEl = canvasRef.current;
    };
  }, [canvasRef, drawRef]);

  useEffect(() => {     // draw;
    drawRef?.current?.draw(rotation, peak);
  }, [rotation, peak, drawRef]);

  return canvasRef;
};
