import {
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';





////////////////////////////////////////////////////////////////////////////////
// getClockStr() IIFE wrapper

const getClockStr = (function gen_getClockStr() {
  const targetMs = 163683180e4;
  const msDivisors = [
    864e5,  // days
    36e5,   // hours
    6e4,    // minutes
    1e3,    // seconds
  ];
  const digitLengths = [3, 2, 2, 2];

  function getDeltaMs() {
    return targetMs - (new Date()).getTime();
  };

  function msToUnitBins(ms = 0) {
    const unitBins = [];
    let rem = ms;
    for (const d of msDivisors) {
      const unitDelta = (rem / d);
      const deltaInt = Math.floor(unitDelta);
      const unitRem = (unitDelta - deltaInt);
      unitBins.push(deltaInt);
      rem = unitRem * d;
    };
    return unitBins;
  };

  function unitBinsToClockString(unitBins = []) {
    const lz = (v, n) => `${'0'.repeat(n)}${v}`.slice(-n);
    const paddedUnitBins = unitBins.map((d, i) => lz(d, digitLengths[i]));
    const outStr = paddedUnitBins.join(':');
    return outStr;
  };

  return function getClockStr() {
    const deltaMs = getDeltaMs();
    const unitBins = msToUnitBins(deltaMs);
    const clockStr = unitBinsToClockString(unitBins);
    return clockStr;
  };
})();





////////////////////////////////////////////////////////////////////////////////
// Export

export default function useClock() {
  const [clock, setClock] = useState(getClockStr());
  const timeoutRef = useRef(null);

  const clockCb = useCallback(() => {
    setClock(getClockStr());
    timeoutRef.current = setTimeout(clockCb, 1e3);
  }, [setClock, timeoutRef]);

  useEffect(() => {
    if (!timeoutRef.current) {
      clockCb();
    };
  }, [timeoutRef, clockCb]);

  return clock;
};
