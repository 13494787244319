import workerCounter from 'workerize-loader?inline!./workerCounter';    // eslint-disable-line import/no-webpack-loader-syntax
import workerReels from 'workerize-loader?inline!./workerReels';    // eslint-disable-line import/no-webpack-loader-syntax





export default class Machine {

/* ------------------------------------------------------------------ */
/* Private fields */

  // State
  #lastPlayhead;
  #freeze;

  // Worker instantiation
  #workerCounter;
  #workerReels;
  #workerCounterReady;
  #workerReelsReady;

  // Private internal config properties
  #reelParams;

  // Properties exposed via getters/setters
  #scalar;
  #ips;
  #duration;
  #counterDigits;
  #updateCb;



/* ------------------------------------------------------------------ */
/* Static Properties */

  // default values -- Reels parameters
  static DEFAULT_SCALAR = 1;
  static DEFAULT_IPS = 7.5;                 // inches/second
  static DEFAULT_REEL_DIAMETER = 10.5;      // inches
  static DEFAULT_TAPE_DIAMETER = 9.5;       // inches
  static DEFAULT_HUB_DIAMETER = 3;          // inches
  static DEFAULT_TAPE_DURATION = 48 * 60;   // seconds

  // default values -- Counter parameters
  static DEFAULT_COUNTER_DIGITS = 5;

  // constraints
  static MIN_SCALAR = 1;
  static MIN_IPS = 1 + (7 / 8);



/* ------------------------------------------------------------------ */
/* Static Methods */

  static VALID_scalar(v) {
    if (typeof v !== 'number') {
      throw new TypeError('scalar value must be a number');
    };
    if (parseInt(v, 10) !== v) {
      throw new TypeError('scalar value must be integer');
    };
    if (v < this.MIN_SCALAR) {
      throw new RangeError(`scalar value must be greater than ${this.MIN_SCALAR}`);
    };
    return v;
  };

  static VALID_ips(v) {
    if (typeof v !== 'number') {
      throw new TypeError('ips value must be a number');
    };
    if (v < this.MIN_IPS) {
      throw new RangeError(`ips value must be greater than ${this.MIN_IPS}`);
    };
    const validMultiple = this.VALID_isPowerOfTwo(v / this.MIN_IPS);
    if (!validMultiple) {
      throw new TypeError(`ips value must be a multiple of ${this.MIN_IPS}`);
    };
    return v;
  };

  static VALID_isPowerOfTwo(n) {
    let reducedN = n;
    while (reducedN > 2) {
      reducedN /= 2;
    };
    if (reducedN !== 2) {
      return false;
    };
    return true;
  };

  static VALID_reelDimensions(dimen) {
    const validDimenArr = Object.entries(dimen)
      .map(d => this.VALID_dimension(d));
    const validDimen = Object.fromEntries(validDimenArr);
    const {
      reelDiameter,
      tapeDiameter,
      hubDiameter,
    } = validDimen;
    if (reelDiameter <= tapeDiameter || reelDiameter <= hubDiameter) {
      throw new RangeError('reelDiameter must be greater than tapeDiameter and greater than hubDiameter');
    };
    if (tapeDiameter >= reelDiameter || tapeDiameter <= hubDiameter) {
      throw new RangeError('tapeDiameter must be less than reelDiameter and greater than hubDiameter');
    };
    if (hubDiameter >= reelDiameter || hubDiameter >= tapeDiameter) {
      throw new RangeError('hubDiameter must be less than reelDiameter and less than tapeDiameter');
    };
    return validDimen;
  };

  static VALID_dimension([key, d]) {
    if (typeof d !== 'number' || d <= 0) {
      throw new TypeError(`${key} value must be a number greater than 0`);
    };
    let val = d;
    return [key, val];
  };



/* ------------------------------------------------------------------ */
/* Getters */

  get ready() {
    if (!this.#workerCounterReady || !this.#workerReelsReady) {
      return false;
    };
    return true;
  };

  get scalar() {
    return this.#scalar;
  };

  get ips() {
    return this.#ips;
  };

  get duration() {
    return this.#duration;
  };

  get counterDigits() {
    return this.#counterDigits;
  };



/* ------------------------------------------------------------------ */
/* Setters */

  set scalar(v) {
    void this.constructor.VALID_scalar(v);
    this.#scalar = v;
    this._workerReelsSetParams({
      dimen: this._workerReelsScaleDimen({ ips: this.ips, ...this.#reelParams}, this.scalar),
    });
  };

  set ips(v) {
    void this.constructor.VALID_ips(v);
    Promise.all([
      this._workerCounterSetParams({ ips: v }),
      this._workerReelsSetParams({
        dimen: this._workerReelsScaleDimen({ ips: this.ips, ...this.#reelParams}, this.scalar),
      }),
    ]).then(() => this.#ips = v);
  };

  set duration(s) {
    if (typeof s !== 'number' || s <= 0) {
      throw new TypeError('duration value must be a number greater than 0');
    };
    this.#duration = s;
    this._workerReelsSetParams({ duration: s });
  };

  set counterDigits(v) {
    if (typeof v !== 'number' || v !== parseInt(v, 10)) {
      throw new TypeError('counterDigits value must be an integer greater than 0');
    };
    this.#counterDigits = v;
    this._workerCounterSetParams({ digits: v });
  };

  set updateCb(fn) {
    if (typeof fn !== 'function') {
      throw new TypeError('updateCb must be a function');
    };
    this.#updateCb = fn;
  };



/* ------------------------------------------------------------------ */
/* Constructor */

  constructor({
    ids,
    uiScalar = this.constructor.DEFAULT_SCALAR,
    ips = this.constructor.DEFAULT_IPS,
    reelDiameter = this.constructor.DEFAULT_REEL_DIAMETER,
    tapeDiameter = this.constructor.DEFAULT_TAPE_DIAMETER,
    hubDiameter = this.constructor.DEFAULT_HUB_DIAMETER,
    durationSeconds = this.constructor.DEFAULT_TAPE_DURATION,
    counterDigits = this.constructor.DEFAULT_COUNTER_DIGITS,
  } = {}) {
    /* State */
    this.#lastPlayhead = 0;
    this.#freeze = true;
    /* Worker instantiation */
    this.#workerCounter = new workerCounter();
    this.#workerReels = new workerReels();
    this.#workerCounterReady = false;
    this.#workerReelsReady = false;
    /* Private internal config properties */
    this.#reelParams = this.constructor.VALID_reelDimensions({
      reelDiameter,
      tapeDiameter,
      hubDiameter,
    });
    /* Properties exposed via getters/setters */
    this.#scalar = this.constructor.DEFAULT_SCALAR;
    this.#ips = this.constructor.DEFAULT_IPS;
    this.#duration = this.constructor.DEFAULT_TAPE_DURATION;
    this.#counterDigits = this.constructor.DEFAULT_COUNTER_DIGITS;
    this.#updateCb = () => {};
    /* Initialization */
    this.scalar = uiScalar;
    this.ips = ips;
    this.duration = durationSeconds;
    this.counterDigits = counterDigits;
    this._workerCounterInit(ids.counter, { ips: this.ips, digits: this.counterDigits });
    this._workerReelsInit(
      ids.reels,
      this._workerReelsScaleDimen({ ips: this.ips, ...this.#reelParams}, this.scalar)
    );
    /* Public methods */
    this.getState = this._getState.bind(this);
    this.update = this._update.bind(this);
  };



/* ------------------------------------------------------------------ */
/* Worker -- Counter */

  _workerCounterInit(id, params = {}) {
    this._workerCounterSetParams({ id, ...params })
      .then(() => this.#workerCounterReady = true);
  };

  async _workerCounterSetParams(params = {}) {
    if (!this.#workerCounter) {
      throw new ReferenceError('worker not available');
    };
    return await this.#workerCounter.config(params);
  };



/* ------------------------------------------------------------------ */
/* Worker -- Reels */

  _workerReelsScaleDimen(params = {}, scalar = this.scalar) {
    const scaledParams = {};
    for (const param in params) {
      scaledParams[param] = params[param] * scalar;
    };
    return scaledParams;
  };

  _workerReelsInit(ids, params = {}) {
    this._workerReelsSetParams({ ids, dimen: params })
      .then(() => this.#workerReelsReady = true);
  };

  async _workerReelsSetParams(params = {}) {
    if (!this.#workerReels) {
      throw new ReferenceError('worker not available');
    };
    return await this.#workerReels.config(params);
  };



/* ------------------------------------------------------------------ */
/* Update */

  _getState(playhead) {
    const state = {};
    return Promise.all([
      this.#workerCounter.count(playhead)
        .then(cState => Object.assign(state, cState)),
      this.#workerReels.spin(playhead)
        .then(rState => Object.assign(state, rState)),
    ]).then(() => state);
  };

  async _update(ts, playhead) {
    if (playhead !== this.#lastPlayhead && this.#freeze) {
      this.#freeze = false;
    };
    if (this.#freeze) {
      return null;
    };
    const nextState = await this.getState(playhead);
    if (!Object.keys(nextState).length) {
      this.#freeze = true;
      return null;
    };
    this.#updateCb(nextState);
  };



};
