import {
  memo,
} from 'react';
import p from '../_params';





export default memo(function MechSvgDefs() {
  return (
    <defs>
{/* Filters */}
      <filter id="filter__mech-shadow--xs">
        <feGaussianBlur stdDeviation={1.75} />
      </filter>
      <filter id="filter__mech-shadow--sm">
        <feGaussianBlur stdDeviation={3} />
      </filter>
      <filter id="filter__mech-shadow--md">
        <feGaussianBlur stdDeviation={5} />
      </filter>
      <filter id="filter__mech-shadow--lg">
        <feGaussianBlur stdDeviation={10} />
      </filter>
{/* Linear gradients */}
      <linearGradient
        id="grad__mech-contour--h"
        x1="0%"
        y1="0%"
        x2="100%"
        y2="0%"
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} />
        <stop offset={.5} stopOpacity={0} />
        <stop offset={1} />
      </linearGradient>
      <linearGradient
        id="grad__mech-frame-contour--v"
        x1="0%"
        y1="0%"
        x2="0%"
        y2="100%"
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} />
        <stop offset={.01} stopOpacity={0} />
        <stop offset={.99} stopOpacity={0} />
        <stop offset={1} />
      </linearGradient>
      <linearGradient
        id="grad__mech-frame-contour--h"
        x1="0%"
        y1="0%"
        x2="100%"
        y2="0%"
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} />
        <stop offset={.01} stopOpacity={0} />
        <stop offset={.99} stopOpacity={0} />
        <stop offset={1} />
      </linearGradient>
      <linearGradient
        id="grad__mech-arm-contour--h"
        x1="0%"
        y1="0%"
        x2="100%"
        y2="0%"
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopOpacity={.7} />
        <stop offset={.5} stopOpacity={0} />
        <stop offset={1} stopOpacity={.7} />
      </linearGradient>
      <linearGradient
        id="grad__mech-arm-contour--v"
        x1="0%"
        y1="0%"
        x2="0%"
        y2="100%"
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopOpacity={.7} />
        <stop offset={.05} stopOpacity={.2} />
        <stop offset={.5} stopOpacity={0} />
        <stop offset={.95} stopOpacity={.2} />
        <stop offset={1} stopOpacity={.7} />
      </linearGradient>
      <linearGradient
        id="grad__mech-playhead-contour--v"
        x1="0%"
        y1="0%"
        x2="0%"
        y2="100%"
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} />
        <stop offset={.1} stopOpacity={0} />
        <stop offset={1} />
      </linearGradient>
{/* Radial gradients */}
      <radialGradient
        id="grad__mech-glare"
        cx="50%"
        cy="50%"
        r="50%"
        fx="32%"
        fy="32%"
        fr="4%"
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#ffffff" stopOpacity={.8} />
        <stop offset={.01} stopColor="#ffffff" stopOpacity={.6} />
        <stop offset={.03} stopColor="#ffffff" stopOpacity={.4} />
        <stop offset={.2} stopColor="#ffffff" stopOpacity={.2} />
        <stop offset={.5} stopOpacity={.1} />
      </radialGradient>
      <radialGradient
        id="grad__mech-arm-roller"
        cx={.5}
        cy={.5}
        r={.5}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopOpacity={0} />
        <stop offset={1} stopOpacity={.8} />
      </radialGradient>
      <radialGradient
        id="grad__mech-arm-anchor"
        cx={.5}
        cy={.5}
        r={.5}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} />
        <stop offset={.15} stopOpacity={.9} />
        <stop offset={.31} stopOpacity={.5} />
        <stop offset={.6} stopOpacity={0} />
        <stop offset={1} />
      </radialGradient>
      <radialGradient
        id="grad__mech-capstan-contour"
        cx={.5}
        cy={.5}
        r={.5}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopOpacity={0} />
        <stop offset={.95} stopOpacity={.25} />
        <stop offset={1} stopOpacity={.8} />
      </radialGradient>
      <radialGradient
        id="grad__mech-capstan-roller-contour"
        cx={.5}
        cy={.5}
        r={.5}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopOpacity={0} />
        <stop offset={1} />
      </radialGradient>
{/* Shapes */}
      <rect
        id="rect__mech-frame"
        x={0}
        y={0}
        width={p.width}
        height={p.height}
      />
      <circle
        id="circle__mech-guide"
        cx={p.cxGuide}
        cy={p.cyGuide}
        r={p.rGuide}
      />
      <circle
        id="circle__mech-guide-roller"
        cx={p.cxGuide}
        cy={p.cyGuide}
        r={p.rGuideRoller}
      />
      <circle
        id="circle__mech-guide--shadow"
        cx={p.cxGuide}
        cy={p.cyGuide}
        r={p.rGuide * 1.05}
      />
      <circle
        id="circle__mech-capstan"
        cx={p.cxCapstan}
        cy={p.cyCapstan}
        r={p.rCapstan}
      />
      <circle
        id="circle__mech-capstan--shadow"
        cx={p.cxCapstan}
        cy={p.cyCapstan}
        r={p.rCapstan * 1.05}
      />
      <circle
        id="circle__mech-capstan-roller"
        cx={p.cxCapstan}
        cy={p.cyCapstan}
        r={p.rCapstanRoller}
      />
      <circle
        id="circle__mech-capstan-roller--shadow"
        cx={p.cxCapstan}
        cy={p.cyCapstan}
        r={p.rCapstanRoller * 1.1}
      />
      <path
        id="path__mech-playhead"
        d={`
          M 815,1070
          Q 815,1050 835,1050
          L 1445,1050
          Q 1465,1050 1465,1070
          L 1465,1390
          Q 1465,1410 1445,1410
          Q 1300,1430 1140,1430
          Q 980,1430 835,1410
          Q 815,1410 815,1390
          L 815,1070
          Z
        `}
      />
{/* Groups */}
    <g id="g__mech-frame">
      <use /* Frame fill */
        xlinkHref="#rect__mech-frame"
        stroke="none"
        fill={p.colorDarkWhite}
      />
      <g filter="url(#filter__mech-shadow--md)">
        <use /* Frame edge contour -- vert */
          xlinkHref="#rect__mech-frame"
          stroke="none"
          fill="url(#grad__mech-frame-contour--v)"
        />
        <use /* Frame edge contour -- horiz */
          xlinkHref="#rect__mech-frame"
          stroke="none"
          fill="url(#grad__mech-frame-contour--h)"
          opacity={.7}
        />
      </g>
      <use /* Frame outline */
        xlinkHref="#rect__mech-frame"
        stroke={p.colorOutline}
        strokeWidth={10}
        fill="none"
      />
      <use /* Frame contour -- horiz */
        xlinkHref="#rect__mech-frame"
        stroke="none"
        fill="url(#grad__mech-contour--h)"
        opacity={.4}
      />
    </g>
    <g id="g__mech-guide">
      <use /* Guide shadow */
        xlinkHref="#circle__mech-guide--shadow"
        filter="url(#filter__mech-shadow--md)"
        transform={`translate(${3}, ${3})`}
        fill={p.colorBlack}
        opacity={.5}
      />
      <use /* Guide */
        xlinkHref="#circle__mech-guide"
        stroke={p.colorOutline}
        strokeWidth={1}
        fill={p.colorMetalLight}
      />
      <use /* Guide roller */
        xlinkHref="#circle__mech-guide-roller"
        stroke={p.colorTape}
        strokeWidth={p.widthTape}
        fill={p.colorMetalLight}
      />
      <image /* Guide roller glare */
        href={p.imgRollerBg}
        x={p.cxGuide- p.rGuideRoller}
        y={p.cyGuide - p.rGuideRoller}
        width={p.rGuideRoller * 2}
        height={p.rGuideRoller * 2}
        preserveAspectRatio="none"
      />
    </g>
    <g id="g__mech-capstan">
      <use /* Capstan anchor shadow */
        xlinkHref="#circle__mech-capstan--shadow"
        filter="url(#filter__mech-shadow--md)"
        transform={`translate(${3}, ${3})`}
        fill={p.colorBlack}
        opacity={.5}
      />
      <use /* Capstan anchor */
        xlinkHref="#circle__mech-capstan"
        stroke={p.colorOutline}
        strokeWidth={1}
        fill={p.colorDarkWhite}
      />
      <use /* Capstan anchor grad */
        xlinkHref="#circle__mech-capstan"
        stroke="none"
        fill="url(#grad__mech-capstan-contour)"
      />
      <use /* Capstan roller shadow */
        xlinkHref="#circle__mech-capstan-roller--shadow"
        filter="url(#filter__mech-shadow--sm)"
        transform={`translate(${3}, ${3})`}
        fill={p.colorBlack}
        opacity={.7}
      />
      <use /* Capstan roller */
        xlinkHref="#circle__mech-capstan-roller"
        stroke={p.colorTape}
        strokeWidth={p.widthTape}
        fill={p.colorWhite}
      />
      <use /* Capstan roller grad */
        xlinkHref="#circle__mech-capstan-roller"
        stroke="none"
        fill="url(#grad__mech-capstan-roller-contour)"
        opacity={.8}
      />
    </g>
    <g id="g__mech-playhead">
      <use /* Playhead shadow */
        xlinkHref="#path__mech-playhead"
        filter="url(#filter__mech-shadow--lg)"
        transform={`translate(${12}, ${12})`}
        fill={p.colorBlack}
        opacity={.8}
      />
      <use /* Playhead fill + outline */
        xlinkHref="#path__mech-playhead"
        stroke={p.colorOutline}
        strokeWidth={14}
        fill={p.colorMetalDark}
      />
      <use /* Playhead border trim */
        xlinkHref="#path__mech-playhead"
        stroke={p.colorWhite}
        strokeWidth={4}
        fill="none"
        opacity={.5}
      />
      <use /* Playhead contour -- horiz */
        xlinkHref="#path__mech-playhead"
        stroke="none"
        fill="url(#grad__mech-contour--h)"
        opacity={.2}
      />
      <use /* Playhead contour -- vert */
        xlinkHref="#path__mech-playhead"
        stroke="none"
        fill="url(#grad__mech-playhead-contour--v)"
        opacity={.7}
      />
    </g>
    </defs>
  );
});
