import {
  useEffect,
  useReducer,
} from 'react';
import {
  TapeUI,
} from '../';





////////////////////////////////////////////////////////////////////////////////
/* Machine hook export */

function machineReducer(state, dispatch) {
  return {
    ...state,
    ...dispatch,
  };
};

const machineState = {
/* counter state */
  digitsCounter: [0, 0, 0, 0, 0],
/* machine state */
  rReelLeft: (9.5 / 2) * 100,
  rotationReelLeft: 0,
  rReelRight: (3 / 2) * 100,
  rotationReelRight: 0,
};


export default function useMachine() {
  const [state, dispatch] = useReducer(machineReducer, machineState);

  useEffect(() => {
    TapeUI.machineCb = dispatch;
    return () => {
      TapeUI.machineCb = () => null;
    };
  }, []);

  return state;
};
