import {
  memo,
} from 'react';
import './Machine.css';
import {
  useMachine,
} from '../../hooks';
import {
  Mech,
  Counter,
  Tape,
  Reels,
} from './';





export default memo(function Machine({
  engagePct = 0,
} = {}) {

  const {
    digitsCounter,
    rotationReelLeft,
    rotationReelRight,
    rReelLeft,
    rReelRight,
  } = useMachine();

  return (
    <div className="Machine">
      <Mech
        engagePct={engagePct}
        rReelLeft={rReelLeft}
        rReelRight={rReelRight}
      />
      <Counter
        digits={digitsCounter}
      />
      <Tape
        rLeft={rReelLeft}
        rRight={rReelRight}
      />
      <Reels
        rotationLeft={rotationReelLeft}
        rotationRight={rotationReelRight}
      />
    </div>
  );
});
