import {
  memo,
} from 'react';
import {
  useMeterCanvas,
} from './';





export default memo(function Meter({
  side = '',
  rotation = -48,
  peak = false,
} = {}) {
  const canvasRef = useMeterCanvas({ side, rotation, peak });

  return (
    <canvas ref={canvasRef} className="Meter" />
  );
});
