
export const params = {

/* padding */
  gapButton: 36,
  // gapRow: 60,
  gapRow: 48,

/* button row 1 */
  widthButtonRow1: 168,
  heightButtonRow1: 132,

/* button row 2 */
  widthButtonRow2: 150,
  widthButtonRow2Big: 204,
  // heightButtonRow2: 96,
  heightButtonRow2: 112,


/* button */
  padButton: 5,

/* panel hole */
  rxPanelHole: 2,

/* colors */
  colorPanelHole: '#000000',
  colorButton: '#15150e',
  colorBlue: '#1a5582',
  colorRed: '#ad1a23',







/* button panel hole */
  offsetPanelHole: 4,
  offsetPanelShadow: 6,

/* button extrusion */
  // offsetTop: 9,
  // offsetRight: 3,
  offsetTop: 5,
  offsetRight: 2,

/* icons */
  widthIcon: 150,
  heightIcon: 50,




};





params.width = ((3 * params.gapButton) + (3 * params.widthButtonRow1));
params.height = ((1 * params.gapButton) + params.heightButtonRow1 + params.gapRow + params.heightButtonRow2);

params.yButtonRow1 = (params.gapButton / 2);
params.yButtonRow2 = (params.yButtonRow1 + params.heightButtonRow1 + params.gapRow);





params.buttons = {};

params.buttons.rew = {
  x: (params.gapButton / 2),
  y: params.yButtonRow1,
  width: params.widthButtonRow1,
  height: params.heightButtonRow1,
  color: params.colorButton,
  icon: `
    M 129 11.5
    L 129 38.5
    L 75 27.78
    L 75 38.5
    L 7 25
    L 75 11.5
    L 75 22.22
    Z
  `.replace(/\s+/g, ' '),
};

params.buttons.ff = {
  x: (params.buttons.rew.x + params.buttons.rew.width + params.gapButton),
  y: params.yButtonRow1,
  width: params.widthButtonRow1,
  height: params.heightButtonRow1,
  color: params.colorButton,
  icon: `
    M 21 11.5
    L 75 22.22
    L 75 11.5
    L 143 25
    L 75 38.5
    L 75 27.78
    L 21 38.5
    Z
  `.replace(/\s+/g, ' '),
};

params.buttons.play = {
  x: (params.buttons.ff.x + params.buttons.ff.width + params.gapButton),
  y: params.yButtonRow1,
  width: params.widthButtonRow1,
  height: params.heightButtonRow1,
  color: params.colorButton,
  icon: `
    M 31 11.5
    L 131 25
    L 31 38.5
    Z
  `.replace(/\s+/g, ' '),
};

params.buttons.pause = {
  x: (params.gapButton / 2),
  y: params.yButtonRow2,
  width: params.widthButtonRow2,
  height: params.heightButtonRow2,
  color: params.colorRed,
  label: 'PAUSE',
};

params.buttons.stop = {
  x: (params.buttons.pause.x + params.buttons.pause.width + params.gapButton),
  y: params.yButtonRow2,
  width: params.widthButtonRow2Big,
  height: params.heightButtonRow2,
  color: params.colorButton,
  label: 'STOP',
};

params.buttons.rev = {
  x: (params.buttons.stop.x + params.buttons.stop.width + params.gapButton),
  y: params.yButtonRow2,
  width: params.widthButtonRow2,
  height: params.heightButtonRow2,
  color: params.colorBlue,
  icon: `
    M 119 11.5
    L 19 25
    L 119 38.5
    Z
  `.replace(/\s+/g, ' '),
};
