import {
  memo,
} from 'react';
import './Init.css'
import {
  InitPower,
} from './';





const Unsupported = memo(() => (
  <div className="Init Init--unsupported">
    <h2>This browser does not fully support WebAudio.</h2>
    <br /><br />
    <h4>For best results, please use Chrome.</h4>
  </div>
));





export default memo(function Init({
  load = null,
  progress = 0,
} = {}) {
  if (progress >= 1) {    // loading successful
    return null;
  };
  if (progress === null) {    // unsupported
    return <Unsupported />
  };
  return (
    <div className="Init">
      {(progress < 0)
        ? <InitPower click={load} />
        : <h2>{(progress * 100).toFixed(0)}%</h2>
      }
    </div>
  );
});
