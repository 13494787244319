import {
  Fragment as F,
  memo,
} from 'react';
import p from '../_params';





export default memo(function MechPinch({
  cx = 0,
  cy = 0,
} = {}) {
  return (
    <F>
      <circle /* outer rubber */
        cx={cx}
        cy={cy}
        r={p.rPinch}
        stroke={p.colorTape}
        strokeWidth={p.widthTape}
        fill={p.colorBlack}
      />
      <circle /* inner fill */
        cx={cx}
        cy={cy}
        r={p.rPinchInner}
        stroke="none"
        fill={p.colorMetalLight}
      />
      <image /* angular glare gradient */
        href={p.imgRollerBg}
        x={cx - p.rPinchInner}
        y={cy - p.rPinchInner}
        width={p.rPinchInner * 2}
        height={p.rPinchInner * 2}
        preserveAspectRatio="none"
      />
    </F>
  );
});
