import {
  memo,
} from 'react';





export default memo(function MechPlayhead() {
  return (
    <use xlinkHref="#g__mech-playhead" />
  );
});
