import {
  AudioTape,
  AudioTapeUI,
  polyfills,
} from './engine';
import getMedia from './audio';





const {
  Tape,
  TapeUI,
  media,
  transport,
  compat,
} = (function initialize() {
  const proxyIncompatible = new Proxy({}, {
    get: function(target, prop, receiver) {
      // console.log('proxyIncompatible --- GET', target, prop, receiver)
      return () => null;
    },
    set: function(target, prop, receiver) {
      // console.log('proxyIncompatible --- SET', target, prop, receiver)
    },
  });
  const out = {
    Tape: proxyIncompatible,
    TapeUI: proxyIncompatible,
    media: [],
    transport: proxyIncompatible,
  };
  try {
    polyfills();
    out.Tape = new AudioTape();
    out.TapeUI = new AudioTapeUI(out.Tape);
    const sampleRate = out.Tape.sampleRate;
    out.media = getMedia(sampleRate);
    out.transport = {
      play: out.Tape.play,
      stop: out.Tape.stop,
      rev: out.Tape.rev,
      ff: out.Tape.ff,
      rew: out.Tape.rew,
      pause: out.Tape.stop,
    };
    out.compat = true;
  } catch (err) {
    console.error(err);
    out.compat = false;
  } finally {
    return out;
  };
})();





export {
  Tape,
  TapeUI,
  media,
  transport,
  compat,
};
