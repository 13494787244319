import p from '../_params';





////////////////////////////////////////////////////////////////////////////////
/* Parent params */

const params = {
/* bounding box */
  width: p.width,
  height: p.height,
/* reels */
  xReelLeft: (p.cxReelLeft - p.rReel),
  xReelRight:( p.cxReelRight - p.rReel),
/* mask IDs */
  maskIdLeft: 'mask__reel-bg--left',
  maskIdRight: 'mask__reel-bg--right',
};





////////////////////////////////////////////////////////////////////////////////
/* Component params (generic) */

const defs = {
  cx: p.rReel,
  cy: p.rReel,
  r: p.rReel,
  widthReel: (p.rReel * 2),
  heightReel: (p.rReel * 2),
  offsetReelShadow: (p.rReel / 50),
  imgReelBg: p.imgReelBg,
  colorWhite: '#d8d8d8',
  colorDarkWhite: '#d1d5d7',
  colorDarkGray: p.colorDarkGray,
  colorBlack: p.colorBlack,
};


const reel = {
  y: (p.cyReel - p.rReel),
  width: (p.rReel * 2),
  height: (p.rReel * 2),
};





////////////////////////////////////////////////////////////////////////////////
/* Helper fns */

const genTransform = (deg) => `rotate(${deg} ${p.rReel} ${p.rReel})`;





////////////////////////////////////////////////////////////////////////////////
/* Export */

export {
  params as default,
  defs,
  reel,
  genTransform,
};
