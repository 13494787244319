import {
  memo,
} from 'react';
import './Player.css';
import {
  compat,
} from '../';
import {
  useLoader,
  useTransport,
} from '../hooks';
import {
  Init,
  Machine,
  Meters,
  Transport,
} from './';
import obverse from './_img/hwh_obverse.jpg';
import reverse from './_img/hwh_reverse.jpg';





const Covers = memo(() => (
  <div className="Player-covers">
    <img className="Player-covers__img" src={obverse} alt="album cover (obverse)" />
    <img className="Player-covers__img" src={reverse} alt="album cover (reverse)" />
  </div>
));



const Obsolete = memo(() => (
  <div className="Player Player--unsupported">
    <h2>This browser does not fully support WebAudio.</h2>
    <br /><br />
    <h4>For best results, please use Chrome.</h4>
    <Covers />
  </div>
));





function Player() {
  const {
    load,
    loadProgress,
  } = useLoader();
  const {
    mechEngagePct,
    ...t
  } = useTransport();

  return (
    <div className="Player">
      <Init load={load} progress={loadProgress} />
      <div className="Player__ui">
        <div className="Player__machine">
          <Machine engagePct={mechEngagePct} />
        </div>
        <div className="Player__meters">
          <Meters />
        </div>
        <div className="Player__transport">
          <Transport {...t} />
        </div>
      </div>
      <Covers />
    </div>
  );
};





export default function HOC() {
  if (!compat) {
    return <Obsolete />
  };
  return <Player />
};
