import {
  AudioTapeEvent,
  Loader,
  Player,
} from './modules';





export default class AudioTape {

/* ------------------------------------------------------------------ */
/* Private Fields */

  // Event management + dispatch
  #registeredEventListeners;
  #dispatchEvent;

  // Child class instances
  #Loader;
  #Player;

  // Bound event listeners
  #handleMediaReady;
  #handleMediaOffline;



/* ------------------------------------------------------------------ */
/* Static Properties */

  // default values
  static DEFAULT_SAMPLE_RATE = 48e3;
  static DEFAULT_CHUNK_LENGTH_MS = 20;
  static DEFAULT_LATENCY_MS = 200;
  static DEFAULT_LOOKAHEAD_MS = 500;
  static DEFAULT_SPEED_Q = 1;
  static DEFAULT_PLAYBACK_SPEED = 1;
  static DEFAULT_SCRUB_SPEED = 5;
  static DEFAULT_FLUTTER = 0;



/* ------------------------------------------------------------------ */
/* Static Methods */



/* ------------------------------------------------------------------ */
/* Getters */

  get sampleRate() {
    return this.#Player.sampleRate;
  };

  get channelCount() {
    return this.#Player.channelCount;
  };

  get chunkLengthSamples() {
    return this.#Player.chunkLengthSamples;
  };

  get totalLengthSamples() {
    return this.#Player.totalLengthSamples;
  };

  get totalLengthSeconds() {
    return this.totalLengthSamples / this.sampleRate;
  };

  get active() {
    return this.#Player.active;
  };

  get volume() {
    return this.#Player.volume;
  };

  get playbackSpeed() {
    return this.#Player.playbackSpeed;
  };

  get scrubSpeed() {
    return this.#Player.scrubSpeed;
  };

  get flutter() {
    return this.#Player.flutter;
  };

  get currentSpeed() {
    return this.#Player.currentSpeed;
  };

  get playhead() {
    return this.#Player.playhead;
  };



/* ------------------------------------------------------------------ */
/* Private Getters */

  get _gainNode() {
    return this.#Player._gainNode;
  };



/* ------------------------------------------------------------------ */
/* Setters */

  set volume(n) {
    this.#Player.volume = n;
  };

  set playbackSpeed(n) {
    this.#Player.playbackSpeed = n;
  };

  set scrubSpeed(n) {
    this.#Player.scrubSpeed = n;
  };

  set flutter(n) {
    this.#Player.flutter = n;
  };



/* ------------------------------------------------------------------ */
/* Private Setters */



/* ------------------------------------------------------------------ */
/* Constructor */

  constructor({
    /* Fixed at instantiation */
    sampleRate = this.constructor.DEFAULT_SAMPLE_RATE,
    chunkLengthMs = this.constructor.DEFAULT_CHUNK_LENGTH_MS,
    latencyMs = this.constructor.DEFAULT_LATENCY_MS,
    lookaheadMs = this.constructor.DEFAULT_LOOKAHEAD_MS,
    speedQ = this.constructor.DEFAULT_SPEED_Q,
    /* Exposed via setters */
    playbackSpeed = this.constructor.DEFAULT_PLAYBACK_SPEED,
    scrubSpeed = this.constructor.DEFAULT_SCRUB_SPEED,
    flutter = this.constructor.DEFAULT_FLUTTER,
  } = {}) {
    /* Event management + dispatch */
    this.#registeredEventListeners = Object.fromEntries(
      AudioTapeEvent.EVENT_TYPES.map(eventType => [eventType, new Set()])
    );
    this.#dispatchEvent = this._dispatchEvent.bind(this);
    /* Child class instances */
    this.#Player = new Player({
      sampleRate,
      chunkLengthMs,
      lookaheadMs,
      latencyMs,
      speedQ,
      playbackSpeed,
      scrubSpeed,
      eventEmitter: this.#dispatchEvent,
    });
    this.#Loader = new Loader({
      sampleRate: this.sampleRate,
      channelCount: this.channelCount,
      chunkLengthSamples: this.chunkLengthSamples,
      eventEmitter: this.#dispatchEvent,
    });
    /* Public methods -- event listener registration */
    this.addEventListener = this.addEventListener.bind(this);
    this.removeEventListener = this.removeEventListener.bind(this);
    this.on = this.on.bind(this);
    /* Private methods -- bound event listeners */
    this.#handleMediaReady = this._handleMediaReady.bind(this);
    this.#handleMediaOffline = this._handleMediaOffline.bind(this);
    /* Private event listener registration */
    this.addEventListener('mediaready', this.#handleMediaReady);
    this.addEventListener('mediaoffline', this.#handleMediaOffline);
    /* Public methods -- media */
    this.load = this.#Loader.load;
    this.erase = this.#Loader.unload;
    /* Public methods -- engine */
    this.activate = this.#Player.activate;
    this.deactivate = this.#Player.deactivate;
    /* Public methods -- transport */
    this.play = this.#Player.play;
    this.stop = this.#Player.stop;
    this.rev = this.#Player.rev;
    this.ff = this.#Player.ff;
    this.rew = this.#Player.rew;
  };



/* ------------------------------------------------------------------ */
/* Event listeners -- private bound */

/* */
  _handleMediaReady(e) {
    this.#Player._totalLengthSamples = e.totalLengthSamples;
    this.#Player._loadSampleDataCb = this.#Loader.getSampleData;
  };


/* */
  _handleMediaOffline(e) {
    this.#Player._totalLengthSamples = 0;
    this.#Player._loadSampleData = null;
  };



/* ------------------------------------------------------------------ */
/* Event management + dispatch */

/* instantiate new event object and pass to registered listeners */
  _dispatchEvent(eventType, payload) {
    const event = new AudioTapeEvent({
      type: eventType,
      ctxTime: this.#Player.ctxNow,
      payload,
    });
    for (const cb of this.#registeredEventListeners?.[eventType]) {
      cb(event);
    };
  };



/* ------------------------------------------------------------------ */
/* Event listener registration -- public methods */

/* register callback fn to event type */
  addEventListener(eventType, fn) {
    if (!AudioTapeEvent.EVENT_TYPES.includes(eventType)) {
      throw new TypeError(`Invalid event type: ${eventType}`);
    };
    if (typeof fn !== 'function') {
      throw new TypeError('Event listener callback must be function');
    };
    this.#registeredEventListeners[eventType].add(fn);
  };


/* unregister callback fn from event type */
  removeEventListener(eventType, fn) {
    this.#registeredEventListeners?.[eventType]?.delete(fn);
  };


/* register callback fn to event type; unregistrer with null */
  on(eventType, fn) {
    if (!AudioTapeEvent.EVENT_TYPES.includes(eventType)) {
      throw new TypeError(`Invalid event type: ${eventType}`);
    };
    if (!(fn === null || typeof fn === 'function')) {
      throw new TypeError('Expected function or null');
    };
    if (fn === null) {
      this.#registeredEventListeners[eventType].clear();
    };
    if (typeof fn === 'function') {
      this.#registeredEventListeners[eventType].add(fn);
    };
    return this;
  };



};
