import './Home.css';
import {
  randomHero,
} from '../../_CONTENT';
import {
  Almpk,
} from './';





export default function Home() {
  return (
    <div className="Home">
      <div className="Home__hero" style={{ backgroundImage: `url("${randomHero()}")` }}>
        <div className="Home__logo">
          <Almpk />
        </div>
      </div>
    </div>
  );
};
