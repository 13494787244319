


// join class

function classConcat(...args) {
  return args.join(' ').trim();
};



// toggle class

function classConcatWithToggles(base = '', ...toggles) {
  if (!base || typeof base !== 'string') {
    throw new Error('invalid class format');
  };
  let str = base;
  const mods = [];
  toggles.forEach(t => {
    if (Array.isArray(t)) {
      mods.push(t);
    } else if (typeof t === 'object') {
      mods.push(...Object.entries(t))
    } else if (typeof t === 'string') {
      mods.push([t, true]);
    };
  });
  mods.forEach(([flag, active]) => {
    if (!active || typeof flag !== 'string') return null;
    str += ` ${base}--${flag}`;
  });
  return str.trim();
};





export default {    // eslint-disable-line import/no-anonymous-default-export
  concat: classConcat,
  toggle: classConcatWithToggles,
};
