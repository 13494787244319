import {
  Fragment as F,
  memo,
} from 'react';
import p from '../_params';





export default memo(function MechTape({
  tapePath = '',
} = {}) {
  return (
    <F>
      <path
        d={tapePath}
        stroke={p.colorTape}
        strokeWidth={p.widthTape}
      />
      <path
        d={tapePath}
        stroke={p.colorBlack}
        strokeWidth={p.widthTape * 1.25}
        filter="url(#filter__mech-shadow--xs)"
        transform={`translate(${4}, ${4})`}
        fill="none"
        opacity={.4}
      />
    </F>
  );
});
