import {
  memo,
} from 'react';
import {
  classname as c,
} from '../../libs';





export default memo(function IconHome({
  cl = '',
  ...attr
} = {}) {
  return (
    <svg
      className={c.concat('Icon Icon-home', cl)}
      xmlns="http://www.w3.org/2000/svg"
      width={1050}
      height={1050}
      viewBox="0 0 1050 1050"
      {...attr}
    >
      <path
        d="M525,0 C814.949494,0 1050,235.050506 1050,525 C1050,814.949494 814.949494,1050 525,1050 C235.050506,1050 0,814.949494 0,525 C0,235.050506 235.050506,0 525,0 Z M227.761598,514.167986 L87.1283143,686.583993 C95.9648539,711.889329 106.966457,735.944664 120.133124,758.75 C132.970624,780.985202 147.866287,802.032123 164.820114,821.890763 L166.128314,823.416007 L385.761598,787.832014 L409.562868,729.057023 C370.676976,705.704682 341.317363,676.852341 321.48403,642.5 C301.84903,608.491182 291.550737,569.091815 290.58915,524.301897 L290.562868,522.942977 L227.761598,514.167986 Z M822.238402,514.167986 L759.437132,522.942977 C758.656357,568.295318 748.349303,608.147659 728.51597,642.5 C708.88097,676.508818 679.909263,705.127085 641.60085,728.354803 L640.437132,729.057023 L664.238402,787.832014 L883.871686,823.416007 C901.368479,803.110671 916.70021,781.555336 929.866876,758.75 C942.704376,736.514798 953.483713,713.091314 962.204886,688.47955 L962.871686,686.583993 L822.238402,514.167986 Z M525,57.5 C499.325,57.5 473.65,59.8765625 447.975,64.6296875 L446,65 L367,273 L406,323 C445.666667,301 485.333333,290 525,290 C564.27,290 603.54,300.7811 642.81,322.3433 L644,323 L683,273 L604,65 C577.666667,60 551.333333,57.5 525,57.5 Z"
        stroke="none"
        fill="#C1C5C7"
      />
    </svg>
  );
});
