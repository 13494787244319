import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import './App.css';
import ROUTES from './_ROUTES';
import {
  Nav,
  Footer,
  Home,
  Page,
  AudioTape,
} from './components';





export default function App() {
  return (
    <Router basename={process.env.REACT_APP_BASE_URL}>
      <div className="App">
        <div className="App__main">
          <div className="App__nav">
            <Nav routes={ROUTES} />
          </div>
          <div className="App__content">
            <Switch>
              <Route path={`/:page(${Object.keys(ROUTES).join('|')})`}>
                <Page />
              </Route>
              <Route exact path="/">
                <Home />
              </Route>
              <Redirect from="*" to="/" />
            </Switch>
          </div>
          <div className="App__player">
            <AudioTape />
          </div>
          <div className="App__footer">
            <Footer />
          </div>
        </div>
      </div>
    </Router>
  );
};
