import p from './_params';





////////////////////////////////////////////////////////////////////////////////
/* Export */

export {
  getTapePathLeft,
  getTapePathRight,
};





////////////////////////////////////////////////////////////////////////////////
/* Static segment parameters */

/* _LEFT_ */
const ptLeft = {
/* tapeLeft to guide */
  p0a: {
    cx1: p.cxReelLeft,
    cy1: p.cyReel,
    s1: -1,
    cx2: p.cxGuide,
    cy2: p.cyGuide,
    r2: p.rGuideRoller,
    s2: -1,
  },
/* tapeLeft to arm */
  p0b_1: {
    cx1: p.cxReelLeft,
    cy1: p.cyReel,
    s1: -1,
    r2: p.rArmRoller,
    s2: 1,
  },
/* arm to guide */
  p0b_2: {
    r1: p.rArmRoller,
    s1: 1,
    cx2: p.cxGuide,
    cy2: p.cyGuide,
    r2: p.rGuideRoller,
    s2: -1,
  },
/* guide to playhead */
  p1: {
    cx1: p.cxGuide,
    cy1: p.cyGuide,
    r1: p.rGuideRoller,
    s1: 1,
    cx2: p.cxPlayhead,
    cy2: p.cyPlayhead,
    r2: p.rPlayhead,
    s2: 1,
  },
};



/* _RIGHT_ */
const ptRight = {
/* playhead to capstan */
  p0: {
    cx1: p.cxPlayhead,
    cy1: p.cyPlayhead,
    r1: p.rPlayhead,
    s1: 1,
    cx2: p.cxCapstan,
    cy2: p.cyCapstan,
    r2: p.rCapstanRoller,
    s2: -1,
  },
/* capstan to arm */
  p1a: {
    cx1: p.cxCapstan,
    cy1: p.cyCapstan,
    r1: p.rCapstanRoller,
    s1: -1,
    r2: p.rArmRoller,
    s2: -1,
  },
/* capstan to pinch */
  p1b_1: {
    cx1: p.cxCapstan,
    cy1: p.cyCapstan,
    r1: p.rCapstanRoller,
    s1: -1,
    r2: p.rPinch,
    s2: 1,
  },
/* pinch to arm */
  p1b_2: {
    r1: p.rPinch,
    s1: 1,
    r2: p.rArmRoller,
    s2: -1,
  },
/* arm to tapeRight */
  p2: {
    r1: p.rArmRoller,
    s1: 1,
    cx2: p.cxReelRight,
    cy2: p.cyReel,
    s2: 1,
  },
};





////////////////////////////////////////////////////////////////////////////////
/* Segment path generators */

/* _LEFT_ */
const pLeft = {
/* tapeLeft to guide */
  p0a: (rTape) => getPath(
    Object.assign({
      r1: rTape,
    }, ptLeft.p0a)
  ),
/* tapeLeft to arm */
  p0b_1: (rTape, cxRoller, cyRoller) => getPath(
    Object.assign({
      r1: rTape,
      cx2: cxRoller,
      cy2: cyRoller,
    }, ptLeft.p0b_1)
  ),
/* arm to guide */
  p0b_2: (cxRoller, cyRoller) => getPath(
    Object.assign({
      cx1: cxRoller,
      cy1: cyRoller,
    }, ptLeft.p0b_2)
  ),
/* guide to playhead */
  p1: getPath(ptLeft.p1),
};



/* _RIGHT_ */
const pRight = {
/* playhead to capstan */
  p0: getPath(ptRight.p0),
/* capstan to arm */
  p1a: (cxRoller, cyRoller) => getPath(
    Object.assign({
      cx2: cxRoller,
      cy2: cyRoller,
    }, ptRight.p1a)
  ),
/* capstan to pinch */
  p1b_1: (cxPinch, cyPinch) => getPath(
    Object.assign({
      cx2: cxPinch,
      cy2: cyPinch,
    }, ptRight.p1b_1)
  ),
/* pinch to arm */
  p1b_2: (cxPinch, cyPinch, cxRoller, cyRoller) => getPath(
    Object.assign({
      cx1: cxPinch,
      cy1: cyPinch,
      cx2: cxRoller,
      cy2: cyRoller,
    }, ptRight.p1b_2)
  ),
/* arm to tapeRight */
  p2: (rTape, cxRoller, cyRoller) => getPath(
    Object.assign({
      r2: rTape,
      cx1: cxRoller,
      cy1: cyRoller,
    }, ptRight.p2)
  ),
};





////////////////////////////////////////////////////////////////////////////////
/* Top-level fns */

function getTapePathLeft({
  rTape,
  cxRoller,
  cyRoller
} = {}) {
  const p0a = pLeft.p0a(rTape);
  const p0b_1 = pLeft.p0b_1(rTape, cxRoller, cyRoller);

  const vectorA = p0a && coordsToVector(...p0a);
  const vectorB = p0b_1 && coordsToVector(...p0b_1);
  const angleA = vectorA && vectorToAngle(vectorA);
  const angleB = vectorB && vectorToAngle(vectorB);

  const segments = [];
  if (angleB > angleA) {
    segments.push(p0a);
  } else {
    const p0b_2 = pLeft.p0b_2(cxRoller, cyRoller);
    segments.push(p0b_1, p0b_2);
  };
  segments.push(pLeft.p1);

  return segmentsToPath(segments);
};



function getTapePathRight({
  rTape,
  cxPinch,
  cyPinch,
  cxRoller,
  cyRoller,
}) {
  const segments = [pRight.p0];
  const p1a = pRight.p1a(cxRoller, cyRoller);
  const p1b_1 = pRight.p1b_1(cxPinch, cyPinch);

  const vectorA = p1a && coordsToVector(...p1a);
  const vectorB = p1b_1 && coordsToVector(...p1b_1);
  const angleA = vectorA && vectorToAngle(vectorA);
  const angleB = vectorB && vectorToAngle(vectorB);

  if (angleB !== null && angleB > angleA) {
    segments.push(p1a);
  } else {
    const p1b_2 = pRight.p1b_2(cxPinch, cyPinch, cxRoller, cyRoller);
    segments.push(p1b_1, p1b_2);
  };

  const p2 = pRight.p2(rTape, cxRoller, cyRoller);
  segments.push(p2);

  return segmentsToPath(segments);
};



/* helpers */

function coordsToPath([[x1, y1], [x2, y2]] = []) {
  return `M${x1},${y1} L${x2},${y2} Z`;
};

function segmentsToPath(segments = []) {
  return segments.map(c => c ? coordsToPath(c) : '').join(' ');
};





////////////////////////////////////////////////////////////////////////////////
/* getPath stack */

/* get tangent line between two circles */
function getPath({
/* circle 1 */
  cx1,
  cy1,
  r1,
  s1 = 1,
/* circle 2 */
  cx2,
  cy2,
  r2,
  s2 = 1,
} = {}) {

/* boolean definitions */
  const isReverse = (r2 > r1);
  const isCross = (s1 !== s2);

/* initial definitions */
  const p1 = [cx1, cy1];            // center of circle 1
  const p2 = [cx2, cy2];            // center of circle 2

/* measurement circle definitions */
  let rA = r1,                      // radius of circle A
      rB = r2,                      // radius of circle B
      pA = p1,                      // center of circle A
      pB = p2;                      // center of circle B
  if (isReverse) {
    rA = r2;
    rB = r1;
    pA = p2;
    pB = p1;
  };

/* meat */
  const vectorAB = coordsToVector(pA, pB);
  const lengthAB = vectorToLength(vectorAB);
  const lengthAC = isCross ? (rA + rB) : (rA - rB);
  if (lengthAC > lengthAB) {
    return null;
  };

  let scalar = (lengthAC / lengthAB);
  if (s1 === 1) {
    scalar *= -1;
  };
  const deltaAngle = Math.acos(scalar);
  const baseVectorAC = rotateVector(vectorAB, deltaAngle);
  const vectorAC = scaleVector(baseVectorAC, scalar);
  const pC = applyVector(pA, vectorAC);

  let scalarTrans = (rB / lengthAC);
  if (!isCross) {
    scalarTrans *= -1;
  };

  const vectorCA = coordsToVector(pC, pA);
  const vectorT = scaleVector(vectorCA, scalarTrans);
  const pT1 = applyVector(pC, vectorT);
  const pT2 = applyVector(pB, vectorT);

  const out = [pT1, pT2];
  if (isReverse) {
    out.reverse();
  };

  return out;
};



/* helpers */

function coordsToVector([x1, y1] = [], [x2, y2] = []) {
  return [
    x2 - x1,
    y2 - y1,
  ];
};

function vectorToLength([dx, dy] = []) {
  return Math.sqrt(dx**2 + dy**2);
};

function vectorToAngle([dx, dy] = []) {
  return Math.atan(dy / dx);
};

function rotateVector([dx, dy] = [], rad = 0) {
  return [
    (Math.cos(rad) * dx) - (Math.sin(rad) * dy),
    (Math.sin(rad) * dx) + (Math.cos(rad) * dy),
  ];
};

function scaleVector([dx, dy] = [], scalar = 0) {
  return [
    dx * scalar,
    dy * scalar,
  ];
};

function applyVector([x, y] = [], [dx, dy] = []) {
  return [
    x + dx,
    y + dy,
  ];
};
