import './Footer.css';
import {
  useClock,
} from './';





export default function Footer() {
  const clock = useClock();

  return (
    <footer className="Footer">
      <h5>
        <span className="t-fs8 t-fw8">&copy;2021</span>
        &nbsp;medlin/kozak
      </h5>
      <h5>{clock}</h5>
    </footer>
  );
};
