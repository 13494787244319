import AudioTape, {
  polyfills as enginePolyfills,
} from './AudioTape';
import AudioTapeUI, {
  polyfills as uiPolyfills,
} from './AudioTapeUI';





function polyfills() {
  void enginePolyfills();
  void uiPolyfills();
};





export {
  AudioTape,
  AudioTapeUI,
  polyfills,
};
