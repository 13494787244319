import {
  memo,
} from 'react';
import {
  params,
} from './';





export default memo(function TransportButton({
  x = 0,
  y = 0,
  width = 0,
  height = 0,
  color = params.colorButton,
  icon = '',
  label = '',
  active = false,
  click = null,
} = {}) {

  let offsetX = params.offsetRight;
  let offsetY = params.offsetTop;
  if (active) {
    offsetX *= .2;
    offsetY *= .3;
  };


  return (
    <g
      className="Transport-button"
      onClick={click}
    >
{/* panel hole */}
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        rx={params.rxPanelHole}
        fill={params.colorPanelHole}
      />
      <rect
        x={x + (params.padButton / 2)}
        y={y + (params.padButton / 2)}
        width={width - (params.padButton)}
        height={height - (params.padButton)}
        filter={`url(#filter__transport-shadow--${active ? 'md' : 'lg'})`}
        transform={`translate(${offsetX}, ${offsetY})`}
        fill={params.colorPanelHole}
        opacity={.8}
      />
      <rect
        x={x + params.padButton}
        y={y + params.padButton}
        width={width - (2 * params.padButton)}
        height={height - (2 * params.padButton)}
        fill={color}
      />
      <rect
        x={x + params.padButton}
        y={y + params.padButton}
        width={width - (2 * params.padButton)}
        height={height - (2 * params.padButton)}
        fill="url(#grad__transport-button--contour)"
      />
      <TransportButtonIcon
        xFace={x}
        yFace={y}
        width={width}
        height={height}
        label={label}
        icon={icon}
      />
    </g>
  );
});





function TransportButtonIcon({
  xFace,
  yFace,
  width,
  height,
  label,
  icon,
} = {}) {
  return (
    <g>
      <svg
        x={xFace + ((width - params.widthIcon) / 2)}
        y={yFace + ((height - params.heightIcon) / 2)}
        viewBox={`0 0 ${params.widthIcon} ${params.heightIcon}`}
        width={params.widthIcon}
        height={params.heightIcon}
      >
        <rect
          x={0}
          y={0}
          width={params.widthIcon}
          height={params.heightIcon}
          fill="purple"
          opacity={0}
        />
        {icon && (
          <path
            d={icon}
            stroke="none"
            fill="#FFFFFF"
            opacity={.7}
          />
        )}
        {label && (
          <text
            x={params.widthIcon / 2}
            y={params.heightIcon * .55}
            textLength={label.length * 18}
            lengthAdjust="spacingAndGlyphs"
            textAnchor="middle"
            alignmentBaseline="middle"
            fontFamily="Futura, Helvetica Neue, sans-serif"
            fontSize={params.heightIcon * .55}
            fontWeight={400}
            fill="#FFFFFF"
            stroke="none"
            opacity={.7}
          >{label}</text>
        )}
      </svg>
    </g>
  );
};
