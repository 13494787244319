import {
  memo,
} from 'react';
import p, {
  xDigitIndex,
} from './_params';
import {
  CounterSvgDefs,
  CounterDigit,
} from './'





export default memo(function Counter({
  digits = [],
} = {}) {
  return (
    <svg
      className="Counter"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${p.width} ${p.height}`}
      width={p.width}
      height={p.height}
    >
{/* Defs */}
      <CounterSvgDefs />
{/* Digits */}
      {digits.map((value, index) => (
        <CounterDigit
          key={`digit-${index}`}
          x={xDigitIndex(index)}
          value={value}
        />
      ))}
{/* Frame */}
      <use xlinkHref="#g__counter-frame" />
    </svg>
  );
});
