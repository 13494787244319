import {
  useRef,
  useEffect,
  useState,
  useCallback,
} from 'react';
import {
  Tape,
  transport,
} from '../';





////////////////////////////////////////////////////////////////////////////////
/* Params */

const machine = {
/* Timings */
  engageMs: 500,
  availStates: [
    'play',
    'stop',
    'rev',
    'ff',
    'rew',
    'pause',
  ]
};





////////////////////////////////////////////////////////////////////////////////
/* Transport hook export */


export default function useTransport() {
  const {
    mechEngagePct,
    mechReady,
    toggleMech,
  } = useMechEngagePct(machine.engageMs);

  const [nextPlayState, setNextPlayState] = useState(null);
  const [playState, setPlayState] = useState('stop');

/* trigger mech engagement + set nextPlayState */
  const toggle = useCallback((togglePlayState = '') => {
    if (togglePlayState === 'stop') {
      toggleMech(-1);
    }
    if (machine.availStates.includes(togglePlayState)) {
      return setNextPlayState(togglePlayState);
    };
  }, [toggleMech, setNextPlayState]);

/* handle state change from Tape; call toggle if reqd */
  const handleStateChange = useCallback(e => {
    const { isPlaying } = e;
    if (isPlaying > 0) {
      return toggleMech(1);
    };
    if (playState === 'pause') {
      return toggleMech(1);
    };
    return toggleMech(-1);
  }, [toggleMech, playState]);

/* register event listener to engine + toggle stop */
  useEffect(() => {
    Tape.addEventListener('playbackstatechange', handleStateChange);
    return () => {
      Tape.removeEventListener('playbackstatechange', handleStateChange);
    };
  }, [handleStateChange]);

/* update playState and clear nextPlayState */
  useEffect(() => {
    if (mechReady && !!nextPlayState) {
      setPlayState(nextPlayState);
      setNextPlayState(null);
    };
  }, [mechReady, nextPlayState, setNextPlayState, setPlayState]);

/* call playState fn on player */
  useEffect(() => {
    if (transport?.[playState]) {
      transport?.[playState]();
    };
  }, [playState]);

  return {
    mechEngagePct,
    active: playState,
    toggle,
  };
};





/* helpers */

function useMechEngagePct({
  ms = machine.engageMs,
} = {}) {

  const [active, setActive] = useState(false);
  const [pct, setPct] = useState(0);
  const [startTs, setStartTs] = useState(null);
  const [ready, setReady] = useState(true);

  const rafRef = useRef(null);

  const rafCb = useCallback((ts) => {
    let nextPct = (ts - startTs) / ms;
    if (nextPct >= 1) {
      nextPct = 1;
    };
    if (!active) {
      nextPct = 1 - nextPct;
    };
    setPct(nextPct);
    rafRef.current = requestAnimationFrame(rafCb);
  }, [ms, active, setPct, startTs, rafRef]);

  useEffect(() => {
    if (!ready && !rafRef.current) {
      rafRef.current = requestAnimationFrame(rafCb);
    };
    if (ready && !!rafRef.current) {
      cancelAnimationFrame(rafRef.current);
      rafRef.current = null;
    };
  }, [ready, rafRef, rafCb]);

  useEffect(() => {
    const targetPct = active ? 1 : 0;
    if (ready && pct !== targetPct) {
      setReady(false);
      setStartTs(performance.now());
    };
    if (!ready && pct === targetPct) {
      setReady(true);
      setStartTs(null);
    };
  }, [active, pct, ready, setReady, setStartTs]);

  const toggle = useCallback((nextState = 0) => {
    if (nextState === 0) return null;
    if (nextState === 1) {
      return setActive(true);
    };
    if (nextState === -1) {
      return setActive(false);
    };
  }, [setActive]);


  return {
    mechEngagePct: pct,
    mechReady: ready,
    toggleMech: toggle,
  };
};
