import {
  Fragment as F,
} from 'react';
import './UI.css';
import {
  classname as c,
} from '../../libs';





////////////////////////////////////////////////////////////////////////////////
// UI Wrapper Object

const UI = {};





////////////////////////////////////////////////////////////////////////////////
// UI Elements

UI.Link = ({ href = '', cl = '', children, ...attr } = {}) => (
  <a className={c.concat('ui-link', cl)} href={href} target="_blank" rel="noreferrer" {...attr}>
    {children}
  </a>
);


UI.Heading = ({ cl = '', children, ...attr } = {}) => (
  <h2 className={c.concat('ui-heading', cl)} {...attr}>
    {children}
  </h2>
);


UI.HeadingSmall = ({ cl = '', children, ...attr } = {}) => (
  <h3 className={c.concat('ui-heading-small', 'ui-heading', cl)} {...attr}>
    {children}
  </h3>
);


UI.SubHeading = ({ cl = '', children, ...attr } = {}) => (
  <h4 className={c.concat( cl, 'ui-subheading')} {...attr}>
    {children}
  </h4>
);


UI.List = ({ nb = false, cl = '', children, ...attr } = {}) => (
  <ul className={c.concat(c.toggle('ui-list', { nb }), cl)} {...attr}>
    {children}
  </ul>
);


UI.ListItem = ({ cl = '', children, ...attr } = {}) => (
  <li className={c.concat('ui-list-item', cl)} {...attr}>
    {children}
  </li>
);


UI.Section = ({ flex = true, cl = '', children, ...attr } = {}) => (
  <div className={c.concat(c.toggle('ui-section', { flex }), cl)} {...attr}>
    {children}
  </div>
);


UI.SubSection = ({ cl = '', children, ...attr } = {}) => (
  <div className={c.concat('ui-subsection', cl)} {...attr}>
    {children}
  </div>
);


UI.TextBlock = ({ cl = '', children, ...attr } = {}) => (
  <div className={c.concat('ui-text-block', cl)} {...attr}>
    {children}
  </div>
);


UI.Image = ({ src = '', alt = '', cl = '', children, ...attr } = {}) => (
  <div className={c.concat('ui-image', cl)} {...attr}>
    <div className="ui-image__wrap">
      <img className="ui-image__img" src={src} alt={alt} />
      {children}
    </div>
  </div>
);


UI.ImageOverlay = ({ content = {}, cl = '', children, ...attr } = {}) => (
  <div className={c.concat('ui-image-overlay', cl)} {...attr}>
    <h5 className="t-ital">{content.note}</h5>
    <h5 className="t-fs9 t-fw8">{content.date}</h5>
    <h6>{content.location}</h6>
    {children}
  </div>
);


UI.ImageRich = ({ img = {}, cl = '', children, ...attr } = {}) => (
  <UI.Image src={img?.src} alt={img?.alt} cl={cl} {...attr}>
    <UI.ImageOverlay content={img?.overlay} />
    {children}
  </UI.Image>
);


UI.ButtonX = ({ cl = '', color = "#ffffff", ...attr } = {}) => {
  return (
    <svg
      className={c.concat('ui-button-x', cl)}
      width={10}
      height={10}
      viewBox="0 0 10 10"
      {...attr}
    >
      <path
        d="M1,1 L9,9 Z M1,9 L 9,1 Z"
        stroke={color}
        strokeWidth={1.5}
        fill="none"
      />
    </svg>
  );
};


UI.Overlay = ({ cbClose = null, cl = '', children, ...attr } = {}) => {
  return (
    <div className={c.concat('ui-overlay', cl)} {...attr} onClick={cbClose}>
      <div className="ui-overlay__content">
        <UI.ButtonX cl="ui-overlay__close" onClick={cbClose} />
        {children}
      </div>
    </div>
  );
};





////////////////////////////////////////////////////////////////////////////////
// Utilities

UI.InlineUrls = ({ text = '', urls = [] } = {}) => {
  const splitText = text.split(/\{\d+\}/);
  const mergedArr = splitText.reduce((acc, d, i) => {
    acc.push(d);
    const link = urls?.[i];
    if (link) {
      acc.push({
        url: link.url,
        text: link.text,
      });
    };
    return acc;
  }, []);

  return mergedArr.map(d => (
    typeof d === 'string'
      ? <F key={d}>{d}</F>
      : <UI.Link key={d.url} href={d.url} cl="t-nr">{d.text}</UI.Link>
  ));
};





////////////////////////////////////////////////////////////////////////////////
// Export

export default Object.freeze(UI);
