import {
  memo,
} from 'react';
import p from '../_params';
import {
  useEngage,
} from '../';
import {
  MechSvgDefs,
  MechArm,
  MechGuide,
  MechCapstan,
  MechPinch,
  MechTape,
  MechPlayhead,
} from './';





export default memo(function Mech({
  engagePct = 0,
  rReelLeft = 0,
  rReelRight = 0,
} = {}) {

  const {
    armLeft,
    armRight,
    pinch,
    tapePathLeft,
    tapePathRight,
  } = useEngage({ engagePct, rReelLeft, rReelRight });

  return (
    <svg
      className="Mech Machine-layer"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${p.width} ${p.height}`}
      width={p.width}
      height={p.height}
    >
{/* Defs */}
      <MechSvgDefs />
{/* Frame */}
      <use /* Frame */
        xlinkHref="#g__mech-frame"
      />
{/* Arms */}
      <MechArm
        cx={p.cxArmLeft}
        cy={p.cyArmLeft}
        cxRoller={armLeft.cxRoller}
        cyRoller={armLeft.cyRoller}
        angle={armLeft.angle}
      />
      <MechArm
        cx={p.cxArmRight}
        cy={p.cyArmRight}
        cxRoller={armRight.cxRoller}
        cyRoller={armRight.cyRoller}
        angle={armRight.angle}
      />
{/* Cogs */}
      <MechGuide />
      <MechCapstan />
      <MechPinch
        cx={pinch.cx}
        cy={pinch.cy}
      />
{/* Tape */}
      <MechTape tapePath={tapePathLeft} />
      <MechTape tapePath={tapePathRight} />
{/* Playhead */}
      <MechPlayhead />
    </svg>
  );
});
