import {
  memo,
} from 'react';





export default memo(function MechGuide() {
  return (
    <use xlinkHref="#g__mech-guide" />
  );
});
