import {
  memo,
} from 'react';
import p, {
  genTransform,
} from './_params';
import {
  ReelsSvgDefs,
  Reel,
} from './';





export default memo(function Reels({
  rotationLeft = 0,
  rotationRight = 0,
} = {}) {
  return (
    <svg
      className="Reels Machine-layer"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${p.width} ${p.height}`}
      width={p.width}
      height={p.height}
    >
{/* Defs */}
      <ReelsSvgDefs />
{/* Reels */}
      <g>
        <Reel
          x={p.xReelLeft}
          transform={genTransform(rotationLeft)}
          maskId={p.maskIdLeft}
        />
        <Reel
          x={p.xReelRight}
          transform={genTransform(rotationRight)}
          maskId={p.maskIdRight}
        />
      </g>
    </svg>
  );
});
