import a00 from './00.m4a';
import a01 from './01.m4a';
import a02 from './02.m4a';
import a03 from './03.m4a';
import a04 from './04.m4a';
import a05 from './05.m4a';
import a06 from './06.m4a';
import a07 from './07.m4a';
import a08 from './08.m4a';
import a09 from './09.m4a';
import a10 from './10.m4a';
import a11 from './11.m4a';
import a12 from './12.m4a';
import a13 from './13.m4a';
import a14 from './14.m4a';
import a15 from './15.m4a';
import a16 from './16.m4a';
import a17 from './17.m4a';
import a18 from './18.m4a';
import a19 from './19.m4a';
import a20 from './20.m4a';
import a21 from './21.m4a';
import a22 from './22.m4a';
import a23 from './23.m4a';
import a24 from './24.m4a';
import a25 from './25.m4a';
import a26 from './26.m4a';
import a27 from './27.m4a';
import a28 from './28.m4a';
import a29 from './29.m4a';
import a30 from './30.m4a';
import a31 from './31.m4a';
import a32 from './32.m4a';
import a33 from './33.m4a';
import a34 from './34.m4a';
import a35 from './35.m4a';
import a36 from './36.m4a';
import a37 from './37.m4a';
import a38 from './38.m4a';
import a39 from './39.m4a';
import a40 from './40.m4a';

export default [    // eslint-disable-line import/no-anonymous-default-export
  a00,
  a01,
  a02,
  a03,
  a04,
  a05,
  a06,
  a07,
  a08,
  a09,
  a10,
  a11,
  a12,
  a13,
  a14,
  a15,
  a16,
  a17,
  a18,
  a19,
  a20,
  a21,
  a22,
  a23,
  a24,
  a25,
  a26,
  a27,
  a28,
  a29,
  a30,
  a31,
  a32,
  a33,
  a34,
  a35,
  a36,
  a37,
  a38,
  a39,
  a40,
];
