import {
  useParams,
} from 'react-router-dom';
import './Page.css';
import CONTENT, {
  randomHero
} from '../../_CONTENT';
import {
  PageContent,
} from './';





export default function Page() {
  const { page } = useParams();
  const { title, content } = CONTENT?.[page];

  return (
    <div className="Page">
      <div className="Page__hero" style={{ backgroundImage: `url("${randomHero()}")` }}>
        <h2 className="Page__title">{title}</h2>
      </div>
      <PageContent page={page} content={content} />
    </div>
  );
};
