import worker from 'workerize-loader?inline!./worker';    // eslint-disable-line import/no-webpack-loader-syntax





export default class AnalyserChannel {

/* ------------------------------------------------------------------ */
/* Private fields */

  // Private properties
  #analyser;
  #data;
  #worker;
  #workerReady;

  // Private properties exposed thorugh getters/setters
  #ballistics;
  #peakLevel;
  #peakHold;



/* ------------------------------------------------------------------ */
/* Static Properties */

  // default values
  static DEFAULT_BALLISTICS = (1 / 18);
  static DEFAULT_PEAK_LEVEL = 15;
  static DEFAULT_PEAK_HOLD = 1e3;



/* ------------------------------------------------------------------ */
/* Getters */

  get ready() {
    if (!this.#workerReady) {
      return false;
    };
    return true;
  };

  get ballistics() {
    return this.#ballistics;
  };

  get peakLevel() {
    return this.#peakLevel;
  };

  get peakHold() {
    return this.#peakHold;
  };



/* ------------------------------------------------------------------ */
/* Setters */

  set ballistics(c) {
    if (typeof c !== 'number' || c < 0 || c > 1) {
      throw new TypeError('ballistics value must be a number between 0 and 1');
    };
    this._workerSetParams({ ballistics: c })
      .then(() => this.#ballistics = c);
  };

  set peakHold(s) {
    if (typeof s !== 'number' || s <= 0) {
      throw new TypeError('peakHold value must be a positive number');
    };
    this._workerSetParams({ peakHold: s })
      .then(() => this.#peakHold = s);
  };

  set peakLevel(v) {
    if (typeof v !== 'number') {
      throw new TypeError('peakLevel value must be a number');
    };
    this._workerSetParams({ peakLevel: v })
      .then(() => this.#peakLevel = v);
  };



/* ------------------------------------------------------------------ */
/* Constructor */

  constructor({
    id = '',
    analyser,
    ballistics = this.constructor.DEFAULT_BALLISTICS,
    peakLevel = this.constructor.DEFAULT_PEAK_LEVEL,
    peakHold = this.constructor.DEFAULT_PEAK_HOLD,
  } = {}) {
    /* Private properties */
    this.#analyser = analyser;
    this.#data = new Float32Array(this.#analyser.fftSize);
    this.#worker = new worker();
    this.#workerReady = false;
    /* Private properties exposed thorugh getters/setters */
    this.#ballistics = this.constructor.DEFAULT_BALLISTICS;
    this.#peakLevel = this.constructor.DEFAULT_PEAK_LEVEL;
    this.#peakHold = this.constructor.DEFAULT_PEAK_HOLD;
    /* Initialization */
    this.ballistics = ballistics;
    this.peakLevel = peakLevel;
    this.peakHold = peakHold;
    this._workerInit(id, { fftSize: this.#analyser.fftSize });
    /* Public methods */
    this.analyse = this._analyse.bind(this);
  };



/* ------------------------------------------------------------------ */
/* Worker */

  _workerInit(id, params = {}) {
    this._workerSetParams({ id, ...params })
      .then(() => this.#workerReady = true);
  };

  async _workerSetParams(params = {}) {
    if (!this.#worker) {
      throw new ReferenceError('worker not available');
    };
    return await this.#worker.config(params);
  };



/* ------------------------------------------------------------------ */
/* Analyse */

  _analyse() {
    this.#analyser.getFloatTimeDomainData(this.#data);
    return this.#worker.analyse(this.#data);
  };



};
