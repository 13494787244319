import a44 from './44';
import a48 from './48';





export default function getMediaBySampleRate(sampleRate) {
  if (!sampleRate || sampleRate === 44100) {
    return a44;
  };
  return a48;
};
