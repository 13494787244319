import kili from './img/kili.jpg';
import beneath from './img/beneath.jpg';
import pwm from './img/pwm.jpg';
import bullhill from './img/bullhill.jpg';
import colander from './img/colander.jpg';
import breakneck from './img/breakneck.jpg';
import map20211112lg from './img/map_2021-11-12_lg.jpg';
import map20211112sm from './img/map_2021-11-12_sm.jpg';
import map20211113lg from './img/map_2021-11-13_lg.jpg';
import map20211113sm from './img/map_2021-11-13_sm.jpg';
import map20211114lg from './img/map_2021-11-14_lg.jpg';
import map20211114sm from './img/map_2021-11-14_sm.jpg';
import hero1 from './img/hero1.jpg';
import hero2 from './img/hero2.jpg';
import hero3 from './img/hero3.jpg';
import hero4 from './img/hero4.jpg';





////////////////////////////////////////////////////////////////////////////////
// Images

const images = {
  kili: {
    src: kili,
    alt: 'on the set of kilimanjaro',
    overlay: {
      date: '2012-06-18',
      location: 'brooklyn, ny',
      note: 'on the set of kilimanjaro',
    },
  },
  beneath: {
    src: beneath,
    alt: 'on the set of beneath',
    overlay: {
      date: '2012-08-22',
      location: 'oxford, ct',
      note: 'on the set of beneath',
    },
  },
  pwm: {
    src: pwm,
    alt: 'at the airport after annie\'s first visit to maine',
    overlay: {
      date: '2013-06-26',
      location: 'portland, me',
      note: 'at the airport after annie\'s first visit to maine',
    },
  },
  bullhill: {
    src: bullhill,
    alt: 'atop bull hill, hudson highlands state park',
    overlay: {
      date: '2017-09-02',
      location: 'cold spring, ny',
      note: 'atop bull hill, hudson highlands state park',
    },
  },
  colander: {
    src: colander,
    alt: 'a typical evening at home',
    overlay: {
      date: '2018-01-25',
      location: 'brooklyn, ny',
      note: 'a typical evening at home',
    },
  },
  breakneck: {
    src: breakneck,
    alt: 'atop breakneck ridge, hudson highlands state park',
    overlay: {
      date: '2019-05-26',
      location: 'cold spring, ny',
      note: 'atop breakneck ridge, hudson highlands state park',
    },
  },
};



const maps = {
  map20211112: {
    srcLg: map20211112lg,
    srcSm: map20211112sm,
  },
  map20211113: {
    srcLg: map20211113lg,
    srcSm: map20211113sm,
  },
  map20211114: {
    srcLg: map20211114lg,
    srcSm: map20211114sm,
  },
};



const heroes = [
  hero1,
  hero2,
  hero3,
  hero4,
];



const randomHero = (function(heroSrc = []) {
  const src = [...heroSrc];
  let lastHero = src.pop();

  return function() {
  // random
    const heroIndex = Math.floor(Math.random() * src.length);
    const heroSrc = src.splice(heroIndex, 1)[0];
    src.push(lastHero);
    lastHero = heroSrc;
  // sequential
    // const heroSrc = src.pop();
    // src.unshift(lastHero);
    // lastHero = heroSrc;
  // return
    return heroSrc;
  };
})(heroes);





////////////////////////////////////////////////////////////////////////////////
// Export Parent

const CONTENT = {};





////////////////////////////////////////////////////////////////////////////////
// About

CONTENT.about = {
  title: 'our story',
  content: {
    blocks: [
      {
        text: 'Right after I graduated from NYU, Michael and I were introduced at a little bar on Waverly Place in July 2011, strictly for the purpose of networking in the audio world. That winter, we first worked together as mixer and boom op on a horrible little movie called 36 Saints, on location in the bitter New York cold (often at 5AM). As our professional partnership bloomed, I learned how great it feels to be supported by Michael, whether in the middle of a reservoir booming a robotic monster fish or in the crawl space under a house in the suburbs.',
        img: images.kili,
      },
      {
        text: 'It was on set one day that Michael caught me harmonizing to his rendition of Elliott Smith’s “Miss Misery”—up to that point, I’d never seen him truly excited. He insisted that the two of us make music together. I was so hesitant (read: terrified) to make music of my own, but before I knew it, Michael and I transformed from audio team to musical buddies, recording hours and hours of laughter and banter between songs that only the two of us heard through our headphones. And it was no mystery where the music came from—it was our way of expressing what we felt for one another but couldn’t yet say. Then, almost two years after meeting, we were a couple—finally!',
        img: images.beneath,
      },
      {
        text: 'Since our days working as a field audio team, I’ve moved into more studio-based work and Michael has blossomed into an amazing software engineer / coding wizard. Just look at the reel-to-reel player that he built from scratch! I am so proud of what we’ve learned and inspired in each other over the last ten years. We can’t wait to celebrate with you!',
        img: images.pwm,
      },
    ],
    signature: 'Annie',
  },
};



////////////////////////////////////////////////////////////////////////////////
// Events

CONTENT.events = {
  title: 'events',
  content: {
    days: [
      {
        day: 'Friday',
        date: 'November 12, 2021',
        img: maps.map20211112,
        events: [
          {
            name: 'rehearsal dinner + beers',
            time: '5:30p',
            venue: 'Sloop Brewing Co.',
            venueUrl: 'https://sloopbrewing.com',
            venueLocation: '755 East Dr #106, East Fishkill',
            venueNote: 'entrance at Lime Kiln Rd and South Dr',
            dress: 'casual',
            note: 'Michael\'s all-time favorite brewery!',
          },
        ],
      },
      {
        day: 'Saturday',
        date: 'November 13, 2021',
        img: maps.map20211113,
        events: [
          {
            name: 'ceremony',
            time: '2:30p',
            venue: 'The Chapel Restoration',
            venueUrl: 'https://www.chapelrestoration.org/',
            venueLocation: '45 Market St, Cold Spring',
            venueNote: 'free parking at Metro North station',
            dress: 'festive / cocktail',
          },
          {
            name: 'cocktails + reception',
            time: 'to follow',
            venue: 'Boscobel House + Gardens',
            venueUrl: 'https://boscobel.org/',
            venueLocation: '1601 NY-9D, Garrison',
            venueNote: 'five-minute drive from the chapel',
            note: 'Bring your singing voice and acoustic guitar!',
          },
        ],
      },
      {
        day: 'Sunday',
        date: 'November 14, 2021',
        img: maps.map20211114,
        events: [
          {
            name: 'farewell brunch',
            time: '11:30a',
            venue: 'Heritage Food + Drink',
            venueUrl: 'https://www.heritagefooddrink.com/',
            venueLocation: '1379 U.S. 9, Wappingers Falls',
            venueNote: 'hosted by the bride\'s mother, Nell Joslin',
            dress: 'casual',
            note: 'Give us a kiss goodbye!',
          },
        ],
      },
    ],
  },
};



////////////////////////////////////////////////////////////////////////////////
// Travel

CONTENT.travel = {
  title: 'travel + accommodations',
  content: {
    transport: {
      heading: 'transport',
      text1: 'Cold Spring is about 60 miles north of New York City, east of the Hudson River. We recommend taking a car if you’re hoping to see more of the Hudson Valley during your stay, but the town is also reachable by the Metro-North train from Grand Central Station. The drive and train ride are both about 80-90 minutes from the city.',
      list: [
        {
          text: 'Metro-North train schedule',
          url: 'http://as0.mta.info/mnr/schedules/sched_form.cfm',
        },
        {
          text: 'Metro-North map',
          url: 'http://web.mta.info/mnr/html/mnrmap.htm',
        },
      ],
      text2: 'Free parking for our ceremony is available directly next to the chapel at the Cold Spring train station. Free parking is also available at our reception at Boscobel House and Gardens, which is a five-minute drive from the chapel. Please let us know if you’d like help arranging transportation.',
    },
    accommodations: {
      heading: 'accommodations',
      text: 'There are many nice AirBnB options near Cold Spring, as well as hotels and inns. Please let us know if you’d like help finding accommodations—we know the area and are here to help!',
      list: [
        {
          name: 'Cold Spring Hotel and Café',
          url: 'https://www.coldspringhotelny.com/',
          location: 'Cold Spring',
          desc: 'A modest little hotel in the heart of Cold Spring. Its close proximity (five minute walk) to the Metro North train station and the wedding chapel make this a good option if you prefer not to rent a car for your stay. The host Jimmy is very friendly and helpful. Weekend rates start at about $200/night and include breakfast in the café downstairs. Also available to book through AirBnB.',
        },
        {
          name: 'Courtyard Marriott',
          url: 'https://www.marriott.com/hotels/travel/fhkny-courtyard-fishkill/',
          location: 'Fishkill',
          desc: 'Located in Fishkill, about a 20 minute drive from the wedding chapel. Includes access to amenities like a fitness center and indoor pool. Weekend rates start at about $230/night.',
        },
        {
          name: 'The Garrison',
          url: 'https://thegarrison.com/inn/',
          location: 'Garrison',
          desc: 'A charming four-room inn with stunning mountain views at the Garrison country club. The inn is about a 12 minute drive from the wedding chapel. Weekend rates start at about $150/night.',
        },
        {
          name: 'Hudson River Crest B&B',
          url: 'http://www.thehudsonrivercrest.com/',
          location: 'Fort Montgomery',
          desc: 'A wonderful inn near West Point, close to the trails at Storm King Mountain. The host Lynn is super friendly and makes a delicious breakfast in the morning. Weekend rates start at about $185/night.',
        },
        {
          name: 'Chrystie House B&B',
          url: 'https://chrystiehouse.com/',
          location: 'Beacon',
          desc: 'A restored 19th century mansion turned inn, walking distance from Main Street in Beacon. Weekend rates start at about $185/night.',
        },
      ],
    },
    hiking: {
      heading: 'hiking',
      text: {
        text: 'This is what first brought us to Cold Spring and shouldn’t be missed! There are wonderful views of the Hudson River Valley from the trails in {0} (east side of the river), {1} (west side), and a lot more only a short drive away. The Cornish Trail down the road from downtown Cold Spring is one of our favorites, nice and easy and no scrambling required. The Washburn Trail is great for a more strenuous hike with beautiful views at the top of Bull Hill.',
        urls: [
          {
            text: 'Hudson Highlands State Park',
            url: 'https://www.nynjtc.org/park/hudson-highlands-state-park',
          },
          {
            text: 'Storm King Mountain',
            url: 'https://www.nynjtc.org/park/storm-king-state-park-2',
          },
        ],
      },
      img: images.breakneck,
    },
  },
};



////////////////////////////////////////////////////////////////////////////////
// Registry

CONTENT.registry = {
  title: 'registry',
  content: {
    img: images.bullhill,
    blocks: [
      {
        text: 'Your presence at our wedding is gift enough for us! Seriously, after all this time in isolation, we are so pumped to see you. However, if you want to send us a wedding gift, we are registered at {0} and {1}.',
        urls: [
          {
            text: 'Williams Sonoma',
            url: 'https://www.williams-sonoma.com/registry/rfmf9hxmss/registry-list.html',
          },
          {
            text: 'Bed Bath & Beyond',
            url: 'https://www.bedbathandbeyond.com/store/giftregistry/viewregistryguest/549528954',
          },
        ],
      },
      {
        text: 'Or consider donating to the {0}, a wonderful organization founded by Annie’s grandparents Bill and Mary that works to conserve parks and green spaces in Raleigh, NC.',
        urls: [
          {
            text: 'City of Oaks Foundation',
            url: 'https://cityofoaksfoundation.org/donate-volunteer',
          },
        ],
      },
    ]
  },
};



////////////////////////////////////////////////////////////////////////////////
// Rsvp

CONTENT.rsvp = {
  title: 'rsvp',
  content: {
    img: images.colander,
    text: 'Please RSVP via email to:',
    email: {
      text: 'almpkwedding@gmail.com',
      url: 'mailto:almpkwedding@gmail.com',
    },
    list: {
      heading: 'When you do, let us know:',
      items: [
        'which events you\'ll be attending',
        'any dietary restrictions',
        'your favorite TV show from the \'90s (early aughts also acceptable)',
      ],
    },
  },
};





////////////////////////////////////////////////////////////////////////////////
// Freeze

Object.freeze(CONTENT);



////////////////////////////////////////////////////////////////////////////////
// Export

export {
  randomHero,
  CONTENT as default,
};
