import {
  Fragment as F,
  memo,
  useState,
  useCallback,
} from 'react';
import {
  classname as c,
} from '../../libs';
import {
  UI,
} from '../';





////////////////////////////////////////////////////////////////////////////////
// Sub-page Wrapper Object

const Content = {
  about: memo(About),
  events: memo(Events),
  travel: memo(Travel),
  registry: memo(Registry),
  rsvp: memo(Rsvp),
};





////////////////////////////////////////////////////////////////////////////////
// About

function About({ content = {} } = {}) {
  return (
    <F>
      <h1 className="t-taL">&ensp;“</h1>
      {content.blocks.map((d, i) => (
        <AboutBlock key={`about-${i}`} {...d} />
      ))}
      <h1 className="t-taR">
        ”
        <span className="t-fs5 t-fw8 t-ls15">
          &nbsp;—&nbsp;{content.signature}&emsp;
        </span>
      </h1>
    </F>
  );
};


function AboutBlock({ text = '', img = {} } = {}) {
  return (
    <UI.Section>
      <p>{text}</p>
      <UI.ImageRich img={img} />
    </UI.Section>
  );
};





////////////////////////////////////////////////////////////////////////////////
// Events

function Events({ content = {} } = {}) {
  return (
    <F>
      {content.days.map((d, i) => (
        <UI.Section key={`events-${i}`}>
          <EventText day={d.day} date={d.date} events={d.events} />
          <EventMap img={d.img} />
        </UI.Section>
      ))}
    </F>
  );
};


function EventText({
  day = '',
  date = '',
  events = [],
} = {}) {
  return (
    <div className="Event-text">
      <UI.HeadingSmall cl="Event-text__heading">
        {day}&#8203; <span className="t-fs9 t-fw4 t-ls10 t-nr">{date}</span>
      </UI.HeadingSmall>
      {events.map((d, i) => (
        <EventListing key={`eventtext-${day}-${i}`} {...d} />
      ))}
    </div>
  );
};


function EventListing({
  name = '',
  time = '',
  venue = '',
  venueLocation = '',
  venueNote = '',
  venueUrl = '',
  dress = '',
  note = '',
} = {}) {
  return (
    <div className="Event-listing">
      <br />
      <UI.SubSection>
        <UI.SubHeading cl="t-nr">{name}</UI.SubHeading>
        <h4 className="t-fw2 t-ls12 t-nr">{time}</h4>
      </UI.SubSection>
      <UI.SubSection>
        <h4>
          <UI.Link href={venueUrl}>{venue}</UI.Link>
        </h4>
        <h5>{venueLocation}</h5>
        {venueNote && (
          <h6 className="t-ls12 t-ital">{venueNote}</h6>
        )}
        {dress && (
          <h5 className="t-fw4 t-ls10">
            dress <span className="t-fs9 t-fw2 t-ls08">{dress}</span>
          </h5>
        )}
      </UI.SubSection>
      <UI.SubSection>
        {note && (
          <h5 className="t-ls12 t-ital">{note}</h5>
        )}
      </UI.SubSection>
    </div>
  );
};


function EventMap({ img = {} } = {}) {
  const [expanded, setExpanded] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const cbOpen = useCallback(e => {
    setExpanded(true);
  }, [setExpanded]);

  const cbClose = useCallback(e => {
    setExpanded(false);
    setLoaded(false);
  }, [setExpanded, setLoaded]);

  const handleLoad = useCallback(e => {
    setLoaded(true);
  }, [setLoaded]);

  return (
    <F>
      <div className="Event-map Event-map--modal">
        {expanded && (
          <UI.Overlay cbClose={cbClose} cl={c.toggle('Event-map__overlay', { loaded })}>
            <img className="Event-map__img--lg" src={img.srcLg} alt="map" onLoad={handleLoad} />
          </UI.Overlay>
        )}
        <UI.Image cl="Event-map__img" src={img.srcSm} alt="map" onClick={cbOpen} />
      </div>
      <div className="Event-map Event-map--nomodal">
        <UI.Link href={img.srcLg}>
          <UI.Image cl="Event-map__img" src={img.srcSm} alt="map" />
        </UI.Link>
      </div>
    </F>
  );
};





////////////////////////////////////////////////////////////////////////////////
// Travel

function Travel({ content = {} } = {}) {
  return (
    <F>
      <TravelTransport {...content.transport} />
      <TravelAccommodations {...content.accommodations} />
      <TravelHiking {...content.hiking} />
    </F>
  );
};


function TravelTransport({
  heading = '',
  text1 = '',
  list = [],
  text2 = '',
  img = {},
} = {}) {
  return (
    <UI.Section>
      <UI.TextBlock>
        <UI.Heading>{heading}</UI.Heading>
        <p>{text1}</p>
        <UI.List>
          {list.map((d, i) => (
            <UI.ListItem key={`traveltransport-${i}`}>
              <UI.Link href={d.url}>{d.text}</UI.Link>
            </UI.ListItem>
          ))}
        </UI.List>
        <p>{text2}</p>
      </UI.TextBlock>
    </UI.Section>
  );
};


function TravelAccommodations({
  heading = '',
  text = '',
  list = [],
} = {}) {
  return (
    <UI.Section>
      <UI.TextBlock>
        <UI.Heading>{heading}</UI.Heading>
        <p>{text}</p>
        <UI.List>
          {list.map((d, i) => (
            <UI.ListItem key={`travelaccommodations-${i}`}>
              <h4>
                <UI.Link href={d.url}>{d.name}</UI.Link>
                <span className="t-fs8 t-ls10 t-ital">&nbsp;{d.location}</span>
              </h4>
              <p>{d.desc}</p>
            </UI.ListItem>
          ))}
        </UI.List>
      </UI.TextBlock>
    </UI.Section>
  );
};


function TravelHiking({
  heading = '',
  text = {},
  img,
} = {}) {
  return (
    <UI.Section>
      <UI.TextBlock>
        <UI.Heading>{heading}</UI.Heading>
        <h4>
          <UI.InlineUrls {...text} />
        </h4>
      </UI.TextBlock>
      <UI.ImageRich img={img} />
    </UI.Section>
  );
};





////////////////////////////////////////////////////////////////////////////////
// Registry

function Registry({ content = {} } = {}) {
  return (
    <UI.Section>
      <UI.TextBlock>
        {content.blocks.map((d, i) => (
          <h4 key={`registry-${i}`}>
            <UI.InlineUrls {...d} />
          </h4>
        ))}
      </UI.TextBlock>
      <UI.ImageRich img={content.img} />
    </UI.Section>
  );
};





////////////////////////////////////////////////////////////////////////////////
// Rsvp

function Rsvp({ content = {} } = {}) {
  return (
    <UI.Section>
      <div className="Rsvp__text">
        <div>
          <h2>{content.text}</h2>
          <h4>
            <UI.Link href={content.email.url}>
              {content.email.text}
            </UI.Link>
          </h4>
        </div>
        <UI.TextBlock>
          <h3>{content.list.heading}</h3>
          <UI.List>
            {content.list.items.map((d, i) => (
              <li key={`rsvp-${i}`}>
                <h5 className="t-fw2">&#10687; {d}</h5>
              </li>
            ))}
          </UI.List>
        </UI.TextBlock>
      </div>
      <UI.ImageRich img={content.img} />
    </UI.Section>
  );
};





////////////////////////////////////////////////////////////////////////////////
// Export

export default memo(function PageContent({ page = '', content = {} } = {}) {
  const PageComponent = Content?.[page];

  return (
    <div className="Page-content">
      {PageComponent && (
        <PageComponent content={content} />
      )}
    </div>
  );
});
