import {
  useEffect,
  useState,
  useCallback,
} from 'react';
import p from './_params';
import {
  getTapePathLeft,
  getTapePathRight,
} from './getTapePath';





function deg2rad(deg) {
  return deg / (180 / Math.PI);
};





function useArmRotation({
/* state-specific */
  pct = 0,
/* side-specific */
  angleIdle,
  angleActive,
  cx,
  cy,
} = {}) {

  const getAngleFromPct = useCallback((pct) => {
    const angleSpan = angleActive - angleIdle;
    const angleDelta = pct * angleSpan;
    return angleIdle + angleDelta;
  }, [angleIdle, angleActive]);

  const getCoordsFromAngle = useCallback((angle) => {
    const rad = deg2rad(angle);
    const deltaX = Math.sin(rad) * p.lengthArm;
    const deltaY = Math.cos(rad) * p.lengthArm;
    return {
      cxRoller: cx + deltaX,
      cyRoller: cy - deltaY,
    };
  }, [cx, cy]);

  const [angle, setAngle] = useState(getAngleFromPct(pct));
  const [coords, setCoords] = useState(getCoordsFromAngle(angle));

  useEffect(() => {
    const angle = getAngleFromPct(pct);
    setAngle(angle);
    setCoords(getCoordsFromAngle(angle));
  }, [pct, getAngleFromPct, getCoordsFromAngle, setAngle, setCoords]);

  return {
    angle,
    ...coords,
  };
};





function usePinchRoller({
  /* state-specific */
  pct = 0,
}) {

  const getCoordsFromPct = useCallback((pct) => {
    return {
      cx: p.cxPinch + (pct * p.txPinchActive),
      cy: p.cyPinch + (pct * p.tyPinchActive),
    };
  }, []);

  const [coords, setCoords] = useState(getCoordsFromPct(pct));

  useEffect(() => {
    setCoords(getCoordsFromPct(pct));
  }, [pct, getCoordsFromPct, setCoords]);

  return coords;
};





export default function useEngage({
  /* state-specific */
  engagePct = 0,
  rReelLeft,
  rReelRight,
} = {}) {

  const armLeft = useArmRotation({
    pct: engagePct,
    angleIdle: p.angleArmLeft,
    angleActive: p.angleArmLeftActive,
    cx: p.cxArmLeft,
    cy: p.cyArmLeft,
  });

  const armRight = useArmRotation({
    pct: engagePct,
    angleIdle: p.angleArmRight,
    angleActive: p.angleArmRightActive,
    cx: p.cxArmRight,
    cy: p.cyArmRight,
  });

  const pinch = usePinchRoller({
    pct: engagePct,
  });

  const tapePathLeft = getTapePathLeft({
    rTape: rReelLeft,
    cxRoller: armLeft.cxRoller,
    cyRoller: armLeft.cyRoller,
  });

  const tapePathRight = getTapePathRight({
    rTape: rReelRight,
    cxPinch: pinch.cx,
    cyPinch: pinch.cy,
    cxRoller: armRight.cxRoller,
    cyRoller: armRight.cyRoller,
  });

  return {
    armLeft,
    armRight,
    pinch,
    tapePathLeft,
    tapePathRight,
  };
};
