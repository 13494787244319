import {
  memo,
} from 'react';
import {
  reel as p,
} from './_params';





export default memo(function Reel({
  x = 0,
  transform = '',
  maskId = '',
} = {}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${p.width} ${p.height}`}
      x={x}
      y={p.y}
      width={p.width}
      height={p.height}
    >
      <defs>
        <mask id={maskId}>
          <use
            xlinkHref="#g__reel-bg--mask"
            transform={transform}
          />
        </mask>
      </defs>
      <use xlinkHref="#g__reel-shadow" />
      <use
        xlinkHref="#image__reel-bg"
        mask={`url(#${maskId})`}
      />
      <use
        xlinkHref="#g__reel-ovarlay"
        transform={transform}
      />
    </svg>
  );
});
