import {
  memo,
} from 'react';
import p from '../_params';





export default memo(function TapeReel({
/* state- and side-specific */
  r = 0,
/* side-specific */
  cx = 0,
} = {}) {
  return (
    <circle
      cx={cx}
      cy={p.cyReel}
      r={r}
      fill={p.colorTape}
      stroke={p.colorTape}
      strokeWidth={p.widthTape}
    />
  );
});
