import {
  memo,
} from 'react';





export default memo(function InitPower({
  click = null,
} = {}) {
  return (
    <svg
      className="Init-power"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 10 10"
      width="10"
      height="10"
      onClick={click}
    >
      <path
        d="
          M 3 3
          A 3 3 0 1 0 7 3
          M 5 1.5 L 5 4.75
        "
        fill="none"
        stroke="rgba(255, 255, 255, .7)"
        strokeWidth="12%"
        strokeLinecap="round"
      />
    </svg>
  );
});
