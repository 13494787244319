import {
  memo,
} from 'react';
import './Meters.css';
import {
  useAnalyser,
} from '../../hooks';
import {
  Meter,
} from './';





export default memo(function Meters() {
  const {
    rotationMeterLeft,
    peakMeterLeft,
    rotationMeterRight,
    peakMeterRight,
  } = useAnalyser();

  return (
    <div className="Meters">
      <Meter side="L" rotation={rotationMeterLeft} peak={peakMeterLeft} />
      <Meter side="R" rotation={rotationMeterRight} peak={peakMeterRight} />
    </div>
  );
});
